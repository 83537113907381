import React, { useCallback, useState } from 'react';
import {
  VStack,
  HStack,
  Box,
  Pressable,
  Center,
  Flex,
  Square,
} from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';

import DateFormatter from '@marango/api/src/formatters/dateFormatter';
import { Ticket } from '@marango/api/src/models/Grower/Ticket';

import Label from '../../atoms/Label';
import { Colors } from '../../../Colors';
import JobScheduleDetailIcon from '../../atoms/JobSchedule/FreightJobDetailIcon';
import { TicketTypes } from '@marango/api/src/models/Grower/Types';
import { Icon } from '../../atoms/Icon';

interface Props {
  ticket: Ticket;
  onSelect: (ticket: Ticket) => void;
}

const ScheduleTableTicketDetailCell = (props: Props) => {
  const [bgColor, setBgColor] = useState<ColorType | undefined>();

  const onPressIn = useCallback(() => {
    setBgColor(Colors.DARK_GREY.alpha(0.5).rgb().string());
  }, [setBgColor]);

  const onHoverIn = useCallback(() => {
    setBgColor(Colors.DARK_GREY.alpha(0.2).rgb().string());
  }, [setBgColor]);

  const onPressOut = useCallback(() => {
    setBgColor(undefined);
  }, [setBgColor]);

  const onSelect = useCallback(() => {
    props.onSelect(props.ticket);
  }, [props.onSelect]);

  return (
    <Pressable
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onHoverIn={onHoverIn}
      onHoverOut={onPressOut}
      onPress={onSelect}>
      <Box
        shadow="3"
        borderRadius="10"
        p="2"
        pr="4"
        backgroundColor={bgColor ?? Colors.GREEN.lighten(0.8).hex()}>
        <VStack
          alignItems="stretch"
          justifyContent="stretch"
          space={3}
          flex={1}>
          <HStack justifyContent="stretch">
            <Center mr="2">
              <JobScheduleDetailIcon
                detailType={
                  props.ticket.inOut === TicketTypes.InOut.In ? 'D' : 'P'
                }
              />
            </Center>
            <VStack flex={1}>
              <HStack alignItems="center">
                <HStack flexBasis="33.33%" justifyContent="left">
                  <Label>{props.ticket.inOutString}</Label>
                </HStack>
                <Center flexBasis="33.33%">
                  <Label bold>{props.ticket.ticketNo}</Label>
                </Center>
                <HStack
                  flexBasis="33.33%"
                  alignItems="center"
                  justifyContent="right">
                  <Label>
                    {DateFormatter(props.ticket.timestamp, 'complete')}
                  </Label>
                </HStack>
              </HStack>

              <HStack alignItems="stretch" justifyContent="space-between" flex={1}>
                <Label bold>{props.ticket.commodityKey}</Label>
                <Box justifyContent="center">
                  <Box paddingLeft="2.5">
                    <Icon icon="chevronRight" />
                  </Box>
                </Box>
              </HStack>
            </VStack>
          </HStack>

          <HStack flexGrow={1} justifyContent="space-between">
            <VStack textAlign="left" flexBasis="33.33%">
              <Label bold>Gross</Label>
              <Label>{props.ticket.massGross} T</Label>
            </VStack>

            <VStack textAlign="center" flexBasis="33.33%">
              <Label bold>Tare</Label>
              <Label>{props.ticket.massTare} T</Label>
            </VStack>

            <VStack textAlign="right" flexBasis="33.33%">
              <Label bold>Net</Label>
              <Label>
                {props.ticket.massNet} {props.ticket.unitCode}
              </Label>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    </Pressable>
  );
};

export default ScheduleTableTicketDetailCell;
