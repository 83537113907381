import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "native-base";
import { observer } from "mobx-react-lite";
import { DateTime } from "luxon";
import { useNavigation } from "@react-navigation/core";
import { StackScreenProps } from "@react-navigation/stack";
import { useInfiniteQuery } from "@tanstack/react-query";

import { MarangoApiResponse } from "@marango/api/src/Services/MarangoApi";
import { AsyncLoadable } from "@marango/api/src/AsyncLoadable";
import { RCTI } from "@marango/api/src/models/Grower/RCTI";
import { InvoicesParamList } from "./GrowerRootStackNavigator";
import ScheduleTablePage from "../../Components/pages/ScheduleTablePage";
import ScheduleTableInvoiceDetailCell from "../../Components/molecules/Grower/ScheduleTableInvoiceDetailCell";
import { useGrowerRootStore } from "../../Stores/GrowerPortal/GrowerRootStoreProvider";
import IconButton from "../../Components/atoms/IconButton";
import { Contract } from "@marango/api/src/models/Grower/Contract";
import InfiniteListPage from "../../Components/pages/InfiniteListTablePage";

type Props = StackScreenProps<InvoicesParamList, 'list'>;

const InvoiceListPageContainer = observer<Props>(props => {
  const { userStore } = useGrowerRootStore()
  const navigation = useNavigation();

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch
  } = useInfiniteQuery<MarangoApiResponse.Paginated<readonly RCTI[]>, Error>(
    ['invoices'],
    ({ pageParam }) => userStore.getMarangoApiOrError().getRCTIs(pageParam),
    {
      getNextPageParam: p => p.pages.current_page === p.pages.last_page ? undefined : p.pages.current_page + 1,
      //getNextPageParam: p => undefined,
    }
  )

  const onRefresh = useCallback(() => {
    refetch()
  }, [refetch]);

  const onEndReached = useCallback(() => {
    if (hasNextPage)
      fetchNextPage()
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    const headerLeft = () => (
      <Box marginLeft="3">
        <IconButton icon="refresh" onPress={onRefresh} />
      </Box>
    );

    navigation.setOptions?.({ headerLeft })
  }, [navigation])

  const tableData = AsyncLoadable.fromTanstackQuery(
    data?.pages.reduce<RCTI[]>((all, next) => ([...all, ...next.result]), []),
    error?.message,
    isFetching,
    isFetchingNextPage
  )

  return (
    <InfiniteListPage
      title="RCTIs"
      type="flat-list"
      loadable={tableData}
      onRefresh={onRefresh}
      refreshing={(isFetching && !isFetchingNextPage) || tableData._type === 'loading'}
      isLoadingNext={isFetchingNextPage}
      onListEndReached={onEndReached}
      renderItem={({ item: rcti, index }) => {
        const isFirst = index === 0;
        return (
          <Box m="2" mt={isFirst ? "2" : "0"}>
            <ScheduleTableInvoiceDetailCell
              key={index}
              rcti={rcti}
            />
          </Box>
        )
      }}
    />
  );
})

export default InvoiceListPageContainer;