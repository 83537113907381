import React from 'react';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';
import { Icon, IconType } from './Icon';

type Props = {
  icon: IconType;
  size?: number;
} & Pick<TouchableOpacityProps, 'onPress'>;

const IconButton = (props: Props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <Icon icon={props.icon} size={props.size}/>
    </TouchableOpacity>
  );
};

export default IconButton;
