import React, { PropsWithChildren } from 'react';
import { Box } from 'native-base';
import { Colors, MarangoColor } from '../../../Colors';

type Props = {
  color?: MarangoColor;
  alpha?: number;
  compact?: boolean;
};

const DEFAULT_BACKGROUND_APLHA = 0.5;

function ColorToString(color: MarangoColor, alpha: number): string {
  // @ts-ignore
  return Colors[color].alpha(alpha).rgb().string();
}

const ScheduleTableCellContainer = (props: PropsWithChildren<Props>) => {
  const bgColor =
    props.color === undefined
      ? undefined
      : ColorToString(props.color, props.alpha ?? DEFAULT_BACKGROUND_APLHA);

  const paddingY = props.compact === true ? 1 : 1.5;

  return (
    <Box bg={bgColor} px="3" py={paddingY}>
      {props.children}
    </Box>
  );
};

export default ScheduleTableCellContainer;
