export namespace CommodityTypes {
  export type Id = NominalPrimative<number, 'Commodity.Id'>;

  export type Name = NominalPrimative<string, 'Commodity.Name'>;
  export type Grade = NominalPrimative<string, 'Commodity.Grade'>;
  export type Variety = NominalPrimative<string, 'Commodity.Variety'>;
  export type Season = NominalPrimative<string, 'Commodity.Season'>;
}

export namespace ContractTypes {
  export type Id = NominalPrimative<number, 'Contract.Id'>;
  export type Number = NominalPrimative<number, 'Contract.Number'>;
  export type SiteName = NominalPrimative<string, 'Contract.SiteName'>;
  export type Comments = NominalPrimative<string, 'Contract.Comments'>;

  /** SC: Sale, PC: Purchase */
  export type Type = 'PC' | 'SC';
}

export namespace RCTITypes {
  export type Number = NominalPrimative<string, 'RCTI.SourceId'>;
  export type Paid = NominalPrimative<boolean, 'RCTI.Paid'>;
  export type Amount = NominalPrimative<number, 'RCTI.Amount'>;
}

export namespace WarehouseTypes {
  export type Name = NominalPrimative<string, 'Warehouse.Name'>;
  export namespace Level {
    export type Amount = NominalPrimative<number, 'Warehouse.Level.Amount'>;
  }
}

export namespace TicketTypes {
  export type Id = NominalPrimative<string, 'Ticket.Id'>;
  export type Number = NominalPrimative<string, 'Ticket.Number'>;
  export enum InOut {
    Out = -1,
    In = 1
  }
}
