import React, { useCallback, useEffect } from 'react';
import { Box } from 'native-base';
import { observer } from 'mobx-react-lite';
import { StackScreenProps } from '@react-navigation/stack';

import { WarehousesParamList } from './GrowerRootStackNavigator';
import { useGrowerRootStore } from '../../Stores/GrowerPortal/GrowerRootStoreProvider';
import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import InfiniteListPage from '../../Components/pages/InfiniteListTablePage';
import IconButton from '../../Components/atoms/IconButton';
import { useNavigation } from '@react-navigation/core';
import { Warehouse } from '@marango/api/src/models/Grower/Warehouse';
import { useQuery } from '@tanstack/react-query';
import ScheduleTableWarehouseDetailCell from '../../Components/molecules/Grower/ScheduleTableWarehouseDetailCell';

type Props = StackScreenProps<WarehousesParamList, 'list'>;

const WarehousesListPageContainer = observer<Props>(props => {
  const { navigationStore, userStore } = useGrowerRootStore();
  const navigation = useNavigation();
  // const dataState = warehouseListStore.getEntities();

  const {
    data,
    error,
    isFetching,
    refetch
  } = useQuery<readonly Warehouse[], Error>(
    ['warehouses'], (_) => userStore.getMarangoApiOrError().getWarehouseLevels(),
  )

  const onRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const headerLeft = () => (
      <Box marginLeft="3">
        <IconButton icon="refresh" onPress={onRefresh} />
      </Box>
    );

    navigation.setOptions?.({ headerLeft });
  }, [navigation, onRefresh]);

  const tableData = AsyncLoadable.fromTanstackQuery(
    data,
    error?.message,
    isFetching,
    false
  )

  return (
    <InfiniteListPage
      title="Warehouse Levels"
      type="flat-list"
      loadable={tableData}
      onRefresh={onRefresh}
      refreshing={isFetching}
      isLoadingNext={false}
      onListEndReached={() => { }}
      renderItem={({ item: warehouse, index, separators }) => {
        const isFirst = index === 0;
        return (
          <Box m="2" mb="0">
            <ScheduleTableWarehouseDetailCell warehouse={warehouse} />
          </Box>
        )
      }}
    />
  );
});

export default WarehousesListPageContainer;
