import React from 'react';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  HamburgerIcon,
} from 'native-base';
import { ColorValue } from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import assertNever from '@marango/api/src/utils/assertNever';

const ENTYPO_ICON_SIZE = 18;
const NATIVEBASE_ICON_SIZE = 'sm';

export type IconType =
  | 'calendar'
  | 'chevronDown'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronUp'
  | 'log-out'
  | 'menu'
  | 'refresh'
  | 'download'
  | 'cog'

  | 'contract'
  | 'invoice'
  | 'warehouse'
  | 'ticket'
  | 'truck';

interface Props {
  icon: IconType;
  size?: number
  color?: ColorValue;
}

export const Icon = (props: Props) => {
  switch (props.icon) {
    case 'cog': {
      return <FontAwesome5 name='cog' size={props.size ?? 16} />
    }
    case 'calendar':
      return (
        <Entypo name="calendar" size={ENTYPO_ICON_SIZE} color={props.color} />
      );
    case 'chevronDown':
      return <ChevronDownIcon color={props.color} />;
    case 'chevronLeft':
      return <ChevronLeftIcon color={props.color} />;
    case 'chevronRight':
      return <ChevronRightIcon color={props.color} />;
    case 'chevronUp':
      return <ChevronUpIcon color={props.color} />;
    case 'log-out':
      return (
        <Entypo name="log-out" size={ENTYPO_ICON_SIZE} color={props.color} />
      );
    case 'menu':
      return <HamburgerIcon size={NATIVEBASE_ICON_SIZE} />;
    case 'refresh':
      return (
        <Entypo name="retweet" size={props.size ?? ENTYPO_ICON_SIZE} color={props.color} />
      );
    case 'truck':
      return <FontAwesome5 name='truck' size={props.size ?? 16} />
    case 'ticket':
      return <FontAwesome name='ticket' size={props.size ?? 16} />
    case 'contract':
      return <FontAwesome5 name='file-contract' size={props.size ?? 16} />
    case 'invoice':
      return <FontAwesome5 name='file-invoice-dollar' size={props.size ?? 16} />
    case 'warehouse':
      return <FontAwesome5 name='warehouse' size={props.size ?? 16} />
    case 'download':
      return <FontAwesome5 name='download' size={props.size ?? 16} />

    default:
      assertNever(props.icon);
  }
};
