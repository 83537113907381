import React, { PropsWithChildren } from 'react';
import { Box } from 'native-base';
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box/types';

type BubbleProps = {
  background?: InterfaceBoxProps['background']
  marginTop?: boolean
  marginBottom?: boolean
  marginX?: boolean
  paddingX?: InterfaceBoxProps['paddingX']
  paddingY?: InterfaceBoxProps['paddingY']
};

const Bubble = (props: PropsWithChildren<BubbleProps>) => (
  <Box
    shadow="2"
    paddingX="3"
    paddingY="2"
    marginTop={props.marginTop ? "3" : "0"}
    marginBottom={props.marginBottom ? "3" : "0"}
    marginX={props.marginX ? "3" : "0"}
    background={props.background ?? "white"}
    borderRadius="lg"
    overflow="hidden">
    {props.children}
  </Box>
);

export default Bubble;
