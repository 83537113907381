import _ from 'lodash';
import { Type } from 'class-transformer';
import {
  IsArray,
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsPostalCode,
  IsString,
  ValidateNested,
} from 'class-validator';

import { AddressString } from '../models/Address';
import { DateString, dateStringToLuxon } from '../models/DateString';
import { FreightJob } from '../models/FreightJob/FreightJob';
import { FreightJobDetail, FreightJobDetailTypes } from '../models/FreightJob/FreightJobDetail';
import { ToModel } from '../models/ToModel';
import { nullToUndefined } from '../nullToUndefined';
import { addressFormatter } from '../formatters/addressFormatter';
import { FreightJobTypes } from '../models/FreightJob/Types';
import { Commodity } from '../models/FreightJob/Commodity';
import { CommodityTypes } from '../models/Grower/Types';

export class MarangoJobsResponseDTO
  implements ToModel<ReadonlyArray<FreightJob>>
{
  @IsArray()
  @ValidateNested()
  @Type(_ => MarangoJobsResponseJobDTO)
  result: ReadonlyArray<MarangoJobsResponseJobDTO>;

  constructor(result: ReadonlyArray<MarangoJobsResponseJobDTO>) {
    this.result = result;
  }

  toModel(): readonly FreightJob[] {
    return this.result.map(j => j.toModel());
  }
}

export class MarangoJobsResponseJobDTO implements ToModel<FreightJob> {
  @IsNumber()
  job_uid: number;

  @IsString()
  job_no: string;

  @IsString()
  job_date: DateString;

  @IsNumber()
  units: number;

  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityDTO)
  commodity: MarangoApiResponseCommodityDTO;

  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityGradeDTO)
  commodity_grade: MarangoApiResponseCommodityGradeDTO;

  @ValidateNested()
  @Type(_ => MarangoApiResponseUnitDTO)
  unit: MarangoApiResponseUnitDTO;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(_ => MarangoJobsResponseJobDetailDTO)
  job_detail: ReadonlyArray<MarangoJobsResponseJobDetailDTO>;

  constructor(
    job_uid: number,
    job_no: string,
    job_date: string,
    units: number,
    commodity: MarangoApiResponseCommodityDTO,
    commodity_grade: MarangoApiResponseCommodityGradeDTO,
    unit: MarangoApiResponseUnitDTO,
    job_detail: ReadonlyArray<MarangoJobsResponseJobDetailDTO>,
  ) {
    this.job_uid = job_uid;
    this.job_no = job_no;
    this.job_date = job_date as DateString;
    this.units = units;
    this.commodity = commodity;
    this.commodity_grade = commodity_grade;
    this.unit = unit;
    this.job_detail = job_detail;
  }

  toModel(): FreightJob {
    const details = this.job_detail.map(d => d.toModel());

    return new FreightJob(
      this.job_uid as FreightJobTypes.Id,
      this.job_no as FreightJobTypes.Number,
      details,
      {
        grade: this.commodity_grade.grade_name,
        type: _.startCase(_.toLower(this.commodity.commodity_name)),
      },
      {
        name: _.startCase(_.toLower(this.unit.multiple_name)),
        volume: this.units,
      },
    );
  }
}

export class MarangoApiResponseCommodityDTO {
  @IsString()
  @IsNotEmpty()
  commodity_name!: string;

  getCommodityName(): CommodityTypes.Name {
    return _.startCase(_.toLower(this.commodity_name)) as CommodityTypes.Name
  }
}

export class MarangoApiResponseCommodityGradeDTO {
  @IsNotEmpty()
  @IsString()
  grade_name!: string;

  getCommodityGrade(): CommodityTypes.Grade {
    return this.grade_name as CommodityTypes.Grade
  }
}

export class MarangoApiResponseCommodityVarietyDTO {
  @IsString()
  @IsNotEmpty()
  variety_name!: string;

  getCommodityVariety(): CommodityTypes.Variety {
    return _.startCase(_.toLower(this.variety_name)) as CommodityTypes.Variety
  }
}

export class MarangoApiResponseUnitDTO {
  @IsNotEmpty()
  @IsString()
  single_name!: string;

  @IsNotEmpty()
  @IsString()
  multiple_name!: string;

  @IsNotEmpty()
  @IsString()
  unit_code!: string;
}

export class MarangoJobsResponseJobDetailDTO
  implements ToModel<FreightJobDetail>
{
  @IsNumber()
  job_id: FreightJobTypes.Id;

  @IsString()
  job_no: FreightJobTypes.Number;

  @IsIn(['D', 'P'])
  detail_type: FreightJobDetailTypes.Type;

  @IsString()
  @IsNotEmpty()
  detail_date_from: DateString;

  detail_date_to: DateString | null;

  detail_time: string | null;
  detail_time_to: string | null;

  // Release No
  con_note_num: FreightJobDetailTypes.ReleaseNumber | null;
  approx_weight: number | null;
  // approx_weight: 38;
  // acc_who: null;
  // tolerance: null;
  comments: FreightJobDetailTypes.Comments | null;

  @IsString()
  @IsNotEmpty()
  address: string;

  contact: string | null;
  phone: string | null;
  mobile: string | null;

  contact2: string | null;
  phone2: string | null;
  mobile2: string | null;

  @ValidateNested()
  @Type(() => MarangoApiResponseJobDetailCityDTO)
  location_city: MarangoApiResponseJobDetailCityDTO;

  constructor(
    job_id: FreightJobTypes.Id,
    job_no: FreightJobTypes.Number,
    detail_type: FreightJobDetailTypes.Type,
    detail_date_from: DateString,
    detail_date_to: DateString | null,
    detail_time: string | null,
    detail_time_to: string | null,
    con_note_num: FreightJobDetailTypes.ReleaseNumber | null,
    approx_weight: number | null,
    comments: FreightJobDetailTypes.Comments | null,
    address: string,
    contact: string | null,
    phone: string | null,
    mobile: string | null,
    contact2: string | null,
    phone2: string | null,
    mobile2: string | null,
    location_city: MarangoApiResponseJobDetailCityDTO,
  ) {
    this.job_id = job_id;
    this.job_no = job_no;
    this.detail_type = detail_type;
    this.detail_date_from = detail_date_from;
    this.detail_date_to = detail_date_to;
    this.detail_time = detail_time;
    this.detail_time_to = detail_time_to;
    this.con_note_num = con_note_num;
    this.approx_weight = approx_weight;
    this.comments = comments;
    this.address = address;
    this.contact = contact;
    this.phone = phone;
    this.mobile = mobile;
    this.contact2 = contact2;
    this.phone2 = phone2;
    this.mobile2 = mobile2;
    this.location_city = location_city;
  }

  toModel(): FreightJobDetail {
    const address = addressFormatter(
      this.address,
      this.location_city.city,
      this.location_city.postcode,
    );

    let contact: FreightJobDetailTypes.Contact | undefined;
    let contact2: FreightJobDetailTypes.Contact | undefined;

    if (this.contact || this.phone || this.mobile) {
      contact = {
        name: nullToUndefined(this.contact),
        mobile: nullToUndefined(this.mobile),
        phone: nullToUndefined(this.phone),
      };
    }

    if (this.contact2 || this.phone2 || this.mobile2) {
      contact2 = {
        name: nullToUndefined(this.contact2),
        mobile: nullToUndefined(this.mobile2),
        phone: nullToUndefined(this.phone2),
      };
    }

    return new FreightJobDetail(
      this.job_id,
      this.detail_type,
      dateStringToLuxon(this.detail_date_from),
      this.detail_date_to ? dateStringToLuxon(this.detail_date_to) : undefined,
      nullToUndefined(this.detail_time),
      nullToUndefined(this.detail_time_to),
      nullToUndefined(this.approx_weight) as FreightJobDetailTypes.Weight,
      address as AddressString,
      contact,
      contact2,
      this.con_note_num as FreightJobDetailTypes.ReleaseNumber,
      nullToUndefined(this.comments),
    );
  }
}

export class MarangoApiResponseJobDetailCityDTO {
  @IsString()
  readonly city: string;

  @IsPostalCode('any')
  readonly postcode: string;

  constructor(city: string, postcode: string) {
    this.city = city;
    this.postcode = postcode;
  }
}
