import { DateTime } from 'luxon';
import { action, makeAutoObservable } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { AsyncLoadable } from '../AsyncLoadable';

const DATA_NOT_FOUND_MESSAGE = 'Job not found!';

export class EntityStore<Identifier extends number, Entity> {
  private _entities: Partial<Record<
    Identifier,
    AsyncLoadable<Entity>
  >> = { };

  constructor(
    private readonly _requester: (id: Identifier) => Promise<Entity | undefined>,
    private readonly _idExtractor: (e: Entity) => Identifier
  ) {
    makeAutoObservable(this);
    // makeLoggable(this);
  }

  getEntity(id: Identifier): AsyncLoadable<Entity> | undefined {
    return this._entities[id];
  }

  @action
  setEntityError(id: Identifier, message: string): void {
    this._entities[id] = {
      _type: 'error',
      error: message,
    };
  }

  @action
  setEntityLoading(id: Identifier, invalidate: boolean = false): void {
    const existingJob = this._entities[id];

    let newEntry: AsyncLoadable.Loading<Entity>;

    if (
      existingJob !== undefined &&
      existingJob._type !== 'error' &&
      !invalidate
    ) {
      newEntry = {
        _type: 'loading',
        data: existingJob.data,
      };
    } else {
      newEntry = { _type: 'loading' };
    }

    this._entities[id] = newEntry;
  }

  @action
  setEntityLoaded(id: Identifier, job: Entity): void {
    this._entities[id] = {
      _type: 'loaded',
      data: job,
      lastRefresh: DateTime.now(),
    };
  }

  async refreshEntityById(
    id: Identifier,
  ): Promise<void> {
    this.setEntityLoading(id)
    const updatedEntity = await this._requester(id);
    if (updatedEntity === undefined) {
      this.setEntityError(id, DATA_NOT_FOUND_MESSAGE);
    } else {
      const id = this._idExtractor(updatedEntity)
      this.setEntityLoaded(id, updatedEntity);
    }
  }
}
