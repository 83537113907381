import React, { useCallback } from 'react';
import { Box, useDisclose, View } from 'native-base';
import styled from 'styled-components';

import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import { ITimestamped } from '@marango/api/src/time/ITimestamped';
import { DateRange } from '@marango/api/src/time/dateRange';
import ScrollViewWithRefresh from '../molecules/Layout/ScrollViewWithRefresh';
import DateNavigationHeader from '../organism/DateNavigationHeader';
import ScheduleTable from '../organism/ScheduleTable';
import { YearAndMonthCode } from '@marango/api/src/models/DateCodes';

const TableContainer = styled(Box)({
  height: '100%',
  userSelect: 'none'
})

type PropsBase<D> = {
  range: DateRange;
  data: AsyncLoadable<ReadonlyArray<D>>;
  hideEmptyDays?: boolean
  hideItemSeperator?: boolean
  hideHeader?: boolean
  onRefresh: () => void;
  onEndReached?: () => void;
  renderDetail: (detail: D, key: number, isFirst: boolean, isLast: boolean) => React.ReactElement;
};

type Props<D> =
  | PropsBase<D> & { headerDateStyle: 'fixed'; title: string; }
  | PropsBase<D> & { headerDateStyle: 'week'; onNavigate: (range: DateRange) => void; onNavigateToToday: () => void; }
  | PropsBase<D> & { headerDateStyle: 'month'; onNavigate: (range: YearAndMonthCode) => void; onNavigateToToday: () => void; }

export default function ScheduleTablePage<D extends ITimestamped>(
  props: Props<D>,
) {
  const onRefresh = useCallback(() => {
    props.onRefresh();
  }, [props]);

  const onNavigate = useCallback(
    (newRange: any) => {
      if (props.headerDateStyle === 'fixed') { return }
      props.onNavigate(newRange);
    },
    [props],
  );

  const isRefreshing = props.data._type === 'loading';

  let header: JSX.Element | null
  if (props.hideHeader) {
    header = null
  } else if (props.headerDateStyle === "fixed") {
    header = <DateNavigationHeader
      style={props.headerDateStyle}
      title={props.title}
      onNavigation={onNavigate}
      onRefresh={onRefresh}
    />
  } else {
    header = <DateNavigationHeader
      style={props.headerDateStyle}
      range={props.range}
      onNavigation={onNavigate}
      onRefresh={onRefresh}
    />
  }

  return (
    <TableContainer>
      {header}
      <ScrollViewWithRefresh
        background="white"
        refreshing={isRefreshing}
        onRefresh={onRefresh}>
        <ScheduleTable
          loadable={props.data}
          hideEmptyDays={props.hideEmptyDays}
          hideHeaders={props.hideHeader}
          hideItemSeperator={props.hideItemSeperator}
          onEndReached={props.onEndReached}
          range={props.range}
          renderDetail={props.renderDetail}
        />
      </ScrollViewWithRefresh>
    </TableContainer>
  );
}
