import { IsNumber } from 'class-validator';

export class MarangoVersionResponseDTO {
  @IsNumber()
  version_no: number;

  constructor(version_no: number) {
    this.version_no = version_no;
  }
}
