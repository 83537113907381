import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';

import GrowerPortal from '@marango/base/src/Entrypoints/GrowerPortal';
import { withProviders } from '@marango/base/src/withProviders';

import './index.css';
import reportWebVitals from './reportWebVitals';

// @ts-ignore
// Generate required css
const iconFont = require('react-native-vector-icons/Fonts/Entypo.ttf');
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: Entypo;
}`;

const fa5IconFont = require('react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf');
const fa5IconFontStyles = `@font-face {
  src: url(${fa5IconFont});
  font-family: FontAwesome5_Solid;
}`;

const faIconFont = require('react-native-vector-icons/Fonts/FontAwesome.ttf');
const faIconFontStyles = `@font-face {
  src: url(${faIconFont});
  font-family: FontAwesome;
}`;


const overscrollStyles = `body {
  overscroll-behaviour: contain;
  overflow: hidden;
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
style.appendChild(document.createTextNode(iconFontStyles));
style.appendChild(document.createTextNode(fa5IconFontStyles));
style.appendChild(document.createTextNode(faIconFontStyles));
style.appendChild(document.createTextNode(overscrollStyles));

// Inject stylesheet
document.head.appendChild(style);

ReactDOM.render(
  <React.StrictMode>{withProviders(<GrowerPortal />)}</React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
