import { Linking, Platform } from 'react-native';

export default function TelephoneLinker(phoneNumber: string) {
  let link: string;

  if (Platform.OS !== 'android' && Platform.OS !== 'web') {
    link = `telprompt:${phoneNumber}`;
  } else {
    link = `tel:${phoneNumber}`;
  }

  Linking.canOpenURL(link).then(
    supported => supported && Linking.openURL(link),
  );
}
