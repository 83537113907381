import React from 'react';
import ContentLoader, { Circle, Rect } from 'react-content-loader/native';

const ScheduleTableDetailRowSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={70}
      viewBox="0 0 220 40"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <Rect x="12" y="4" rx="2" ry="2" width="30" height="10" />
      <Rect x="12" y="21" rx="2" ry="2" width="30" height="10" />
      <Circle cx="64" cy="17" r="13" />
      <Rect x="88" y="4" rx="2" ry="2" width="110" height="10" />
      <Rect x="88" y="21" rx="2" ry="2" width="110" height="10" />
    </ContentLoader>
  );
};

export default ScheduleTableDetailRowSkeleton;
