import { DateTime } from "luxon";
import { ITimestamped } from "../../time/ITimestamped";
import { DateString, dateStringToLuxon } from "../DateString";
import { RCTITypes } from "./Types";

export class RCTI implements ITimestamped {
  public constructor(
    readonly invoiceNo: RCTITypes.Number,
    readonly invoiceDate: DateString,
    readonly dueDate: DateString,
    readonly invoiceAmount: RCTITypes.Amount,
    readonly paidDate?: DateString,
  ) { }

  get timestamp(): DateTime {
    return dateStringToLuxon(this.invoiceDate)
  }

  get paid(): RCTITypes.Paid {
    return (this.paidDate !== undefined) as RCTITypes.Paid
  }
}