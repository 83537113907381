import React, { PropsWithChildren, useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { Box, Divider, HStack, VStack } from 'native-base';
import ContentLoader, { Rect } from 'react-content-loader/native';

import Label from '../atoms/Label';

/**
 * Implements a vertical list of items, roughly matches the style of the iOS Settings app.
 */
export namespace TableView {
  export const Container = (props: PropsWithChildren<{}>) => (
    <VStack divider={<Divider marginY="1" />}>{props.children}</VStack>
  );

  export const Cell = <V extends string | number>(props: {
    name?: string;
    value?: V;
    nameBold?: boolean;
    valueBold?: boolean;
    onPress?: (value: V) => void;
    onPressTitle?: (value: V) => void;
    seperateRows?: boolean;
  }) => {
    const { onPress, onPressTitle, value } = props;
    const hasName = value !== undefined;
    const hasValue = value !== undefined;

    const hasNamePressHandler = hasValue && props.onPressTitle !== undefined;
    const hasValuePressHandler = hasValue && props.onPress !== undefined;

    let nameText: string;
    if (props.name !== undefined && props.name.length > 0) {
      if (hasNamePressHandler) {
        nameText = `${props.name}`;
      } else {
        nameText = `${props.name}`;
      }
    } else {
      nameText = '';
    }

    const namePressHandler = useCallback(() => {
      if (hasName && onPressTitle !== undefined) {
        onPressTitle(value);
      }
    }, [onPressTitle, value, hasName]);

    const valuePressHandler = useCallback(() => {
      if (hasValue && onPress !== undefined) {
        onPress(value);
      }
    }, [onPress, value, hasValue]);

    const titleComponent = (
      <Box>
        <TouchableOpacity
          onPress={namePressHandler}
          disabled={!hasNamePressHandler}>
          <Label
            variant="body"
            bold={props.nameBold}
            underlined={hasNamePressHandler}
            color={hasNamePressHandler ? 'gray.600' : 'black'}>
            {nameText}
          </Label>
        </TouchableOpacity>
      </Box>
    );

    const valueComponent = (
      <Box flex={props.seperateRows ? 1 : undefined}>
        <TouchableOpacity
          onPress={valuePressHandler}
          disabled={!hasValuePressHandler}>
          <Box alignContent="flex-end">
            {!hasValue ? (
              <TableViewCellSkeleton />
            ) : (
              <Label
                variant="body"
                bold={props.valueBold}
                wrap
                color="gray.600"
                underlined={hasValuePressHandler}>
                {props.value}
              </Label>
            )}
          </Box>
        </TouchableOpacity>
      </Box>
    );

    return (
      <>
        <HStack
          marginY="1.5"
          width="100%"
          justifyContent="space-between">
            {titleComponent}
            {!props.seperateRows && valueComponent}
          </HStack>

          { props.seperateRows && <HStack
          marginY="1.5"
          width="100%"
          justifyContent="space-between">
            {valueComponent}
          </HStack> }
      </>
    );
  };

  export const HeaderCell = (props: {
    title?: string;
    subtitle?: string;
    skeletonTitle?: boolean;
    skeletonSubtitle?: boolean;
  }) => {
    const subtitleIsSkeleton = props.skeletonSubtitle !== undefined;
    const titleIsSkeleton = props.skeletonTitle !== undefined;

    return (
      <HStack
        justifyContent="space-between"
        alignItems="center"
        marginRight="3">
        {titleIsSkeleton ? (
          <TableViewCellSkeleton />
        ) : (
          <Label variant="header" bold color="gray.900">
            {props.title}
          </Label>
        )}

        {subtitleIsSkeleton ? (
          <TableViewCellSkeleton />
        ) : (
          <Label variant="body" color="gray.600">
            {props.subtitle}
          </Label>
        )}
      </HStack>
    );
  };

  const TableViewCellSkeleton = () => {
    return (
      <ContentLoader
        speed={2}
        width={40}
        height={20}
        viewBox="0 0 40 20"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <Rect x="0" y="0" rx="2" ry="2" width="40" height="20" />
      </ContentLoader>
    );
  };
}
