import { DateTime } from 'luxon';
import { ITimestamped } from '../../time/ITimestamped';

import { AddressString } from '../Address';
import { FreightJobTypes } from './Types';

export namespace FreightJobDetailTypes {
  export type Type = 'P' | 'D';
  export type Comments = NominalPrimative<string, 'JobDetail.Comments'>;
  export type ReleaseNumber = NominalPrimative<
    string,
    'JobDetail.ReleaseNumber'
  >;
  export type Weight = NominalPrimative<number, 'JobDetail.Weight'>;

  export type Contact = {
    readonly name?: string;
    readonly phone?: string;
    readonly mobile?: string;
  };
}

// Represents a single "Stop" (Delivery / Pickup) within a FreightJob
export class FreightJobDetail implements ITimestamped {
  public constructor(
    readonly jobId: FreightJobTypes.Id,
    readonly type: FreightJobDetailTypes.Type,
    readonly startDateTime: DateTime,
    readonly endDateTime: DateTime | undefined,
    readonly startTimeString: string | undefined,
    readonly endTimeString: string | undefined,
    readonly weight: FreightJobDetailTypes.Weight | undefined,
    readonly address: AddressString,
    readonly contact: FreightJobDetailTypes.Contact | undefined,
    readonly contactAlternate: FreightJobDetailTypes.Contact | undefined,
    readonly releaseNumber: FreightJobDetailTypes.ReleaseNumber,
    readonly comments: FreightJobDetailTypes.Comments | undefined,
  ) {}

  /**
   * Bit of a silly name, but returns the endDateTime if it exists, else startDateTime
   */
  get latestDateTime(): DateTime {
    return this.endDateTime ?? this.startDateTime;
  }

  get timestamp(): DateTime {
    return this.startDateTime;
  }

  static typeToString(t: FreightJobDetailTypes.Type): 'Pickup' | 'Delivery' {
    switch (t) {
      case 'D':
        return 'Delivery';
      case 'P':
        return 'Pickup';
    }
  }
}
