import { Box } from 'native-base';
import React from 'react';
import ScheduleTableDetailRowSkeleton from '../../atoms/ScheduleTable/DetailRowSkeleton';

const NUMBER_OF_REPEATS = 10;

const ScheduleTableLoading = () => {
  return (
    <>
      {Array(NUMBER_OF_REPEATS).fill(undefined).map((_, i) =>
        <Box
          key={i}
          shadow="3"
          borderRadius="10"
          p="2"
          m="2">
          <ScheduleTableDetailRowSkeleton />
        </Box>
      )}
    </>
  );
};

export default ScheduleTableLoading;
