import React, { useContext } from 'react';
import { RootStore } from '@marango/api/src/stores/RootStore';

import { GrowerNavigationStore } from './GrowerNavigationStore';
import { AuthTokenStorageServiceMobile } from '@marango/api/src/Services/AuthTokenStorageService';

const ctxValue = {
  navigationStore: new GrowerNavigationStore(),
  ...new RootStore(AuthTokenStorageServiceMobile),
}

const GrowerRootStoreContext = React.createContext(ctxValue);

export const useGrowerRootStore = () => {
  return useContext(GrowerRootStoreContext);
};
