import React from 'react';
import { VStack, HStack, Box, Center } from 'native-base';

import { Warehouse } from '@marango/api/src/models/Grower/Warehouse';

import Label from '../../atoms/Label';
import { Colors } from '../../../Colors';
import { IconWrapper } from '../../atoms/IconWrapper';
import { Icon } from '../../atoms/Icon';

interface Props {
  warehouse: Warehouse;
  // onSelect: (ticket: Ticket) => void;
}

const numberFormatter = Intl.NumberFormat('en-au', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const ScheduleTableWarehouseDetailCell = (props: Props) => {
  return (
    <Box
      shadow="3"
      borderRadius="10"
      p="2"
      pr="4"
      backgroundColor={Colors.GREEN.lighten(0.8).hex()}>
      <VStack alignItems="stretch" justifyContent="stretch">
        <HStack alignItems="center" justifyContent="stretch" flex={1}>
          <Box flexBasis="33.33%">
            <IconWrapper>
              <Icon icon="warehouse" size={16} />
            </IconWrapper>
          </Box>
          <Center flexBasis="33.33%">
            <Label bold>{props.warehouse.siteName}</Label>
          </Center>
          <Box flexBasis="33.33%" />
        </HStack>

        <Center mr="2">
        </Center>

        <Box pl="1" py="1">
          <Label>Stock Levels</Label>
        </Box>
        <Box
          borderRadius="10"
          px="1"
          backgroundColor={Colors.GREEN.lighten(0.9).hex()}
          borderColor={Colors.LIGHT_GREY.hex()}
          borderWidth="1">
          {props.warehouse.levels.map((l, i) => {
            const isFirst = i === 0;
            return (
              <HStack
                mb="1"
                mt={isFirst ? '1' : '0'}
                justifyContent="space-between"
                key={l.commodityKey}
                borderTopColor={Colors.LIGHT_GREY.hex()}
                borderTopWidth={isFirst ? '0' : '1'}>
                <Label>{l.commodityNameAndGrade}</Label>
                <Label>
                  {numberFormatter.format(l.amount)} {l.unitName}
                </Label>
              </HStack>
            );
          })}
        </Box>
      </VStack>
    </Box>
  );
};

export default ScheduleTableWarehouseDetailCell;
