import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigation } from '@react-navigation/core';
import { StackScreenProps } from '@react-navigation/stack';
import { InfiniteData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box } from 'native-base';

import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import { Ticket } from '@marango/api/src/models/Grower/Ticket';
import { TicketsParamList } from './GrowerRootStackNavigator';
import { useGrowerRootStore } from '../../Stores/GrowerPortal/GrowerRootStoreProvider';
import TicketDetailPage from '../../Components/pages/Grower/TicketDetailPage';
import IconButton from '../../Components/atoms/IconButton';
import { RefreshControl } from 'react-native-web-refresh-control';
import { MarangoApiResponse } from '@marango/api/src/Services/MarangoApi';

type Props = StackScreenProps<TicketsParamList, 'detail'>;

const TicketDetailPageContainer = observer<Props>(props => {
  const { id } = props.route.params;
  const { userStore } = useGrowerRootStore()
  const navigation = useNavigation()
  const queryClient = useQueryClient()

  const {
    data,
    error,
    isFetching,
    refetch
  } = useQuery<Ticket | undefined, Error>(
    ['tickets', id], () =>
      userStore.getMarangoApiOrError().getTicketById(id),
    {
      initialData: () => {
        // This seems super verbose but it works...
        const queryData = queryClient.getQueryData<InfiniteData<MarangoApiResponse.Paginated<Ticket[]>>>(['tickets']);
        for (const page of queryData?.pages ?? []) {
          const found = page.result.find(t => t.ticketId === id)
          if (found) {
            return found
          }
        }
      }
    }
  )
  const asyncLoadableData = AsyncLoadable.fromTanstackQuery(data, error?.message, isFetching, false)

  useEffect(() => {
    const headerLeft = () => (
      <Box marginLeft="3">
        <IconButton icon="refresh" onPress={() => refetch()} />
      </Box>
    );

    navigation.setOptions?.({ headerLeft })
  }, [navigation, refetch])

  return <TicketDetailPage ticket={asyncLoadableData} onRefresh={() => refetch()} />
});

export default TicketDetailPageContainer;
