import AsyncStorage from '@react-native-async-storage/async-storage';
import { DateTime } from 'luxon';
import { AuthTokenDetails } from '../models/user/AuthTokenDetails';

export interface IAuthTokenStorageService {
  forgetAuthToken(): Promise<void>
  saveAuthToken(tokenDetails: AuthTokenDetails): Promise<void>
  retrieveAuthToken(): Promise<AuthTokenDetails | undefined>
}

const ENCRPYTED_AUTH_KEY = 'UserAuthDetais.Encrypted';

export const AuthTokenStorageServiceMobile: IAuthTokenStorageService = {
  forgetAuthToken: async () => {
    try {
      await AsyncStorage.removeItem(ENCRPYTED_AUTH_KEY);
    } catch (e) {
      console.error(e);
    }
  },
  retrieveAuthToken: async () => {
    const authItem = await AsyncStorage.getItem(ENCRPYTED_AUTH_KEY);
    if (!authItem) {
      return
    }

    const authItemParsed = JSON.parse(authItem) as AuthTokenDetails | undefined;
    if (!authItemParsed) {
      return
    }

    // Check if token is expired
    const parsedExpires = DateTime.fromISO(authItemParsed.expires)
    if (parsedExpires.diffNow().as('minutes') < 0) {
      await AuthTokenStorageServiceMobile.forgetAuthToken()
      return
    }

    return {
      serverId: authItemParsed.serverId,
      bearerToken: authItemParsed.bearerToken,
      expires: authItemParsed.expires,
    };
  },
  saveAuthToken: async (authToken) => {
    try {
      // Save Auth Token to AsyncStorage
      await AsyncStorage.setItem(ENCRPYTED_AUTH_KEY, JSON.stringify(authToken));
    } catch (e) {
      // Swallow this error for now
      console.error(e);
    }
  }
}
