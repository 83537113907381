import { createNavigationContainerRef } from '@react-navigation/core';
import { makeObservable } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { ContractTypes, TicketTypes } from '@marango/api/src/models/Grower/Types';

import { NavigationStore } from '@marango/api/src/stores/NavigationStore';
import { GrowerRootParamList } from '../../Containers/GrowerPortal/GrowerRootStackNavigator';

export class GrowerNavigationStore extends NavigationStore {
  public navigationRef =
    createNavigationContainerRef<GrowerRootParamList>();

  constructor() {
    super();
    makeObservable(this, {
      navigationRef: true
    })
    makeLoggable(this);
  }

  goBack() {
    if (!this.navigationRef.isReady()) {
      return;
    }

    this.navigationRef.goBack();
  }

  goToContractsListPage() {
    if (!this.navigationRef.isReady()) {
      return;
    }

    this.navigationRef.navigate(
      'contracts',
      {
        screen: 'list'
      }
    );
  }

  goToContractDetailPage(id: ContractTypes.Id) {
    if (!this.navigationRef.isReady()) {
      return;
    }

    this.navigationRef.navigate(
      'contracts',
      {
        screen: 'detail',
        params: { id }
      }
    );
  }

  goToInvoicesListPage() {
    if (!this.navigationRef.isReady()) {
      return;
    }

    this.navigationRef.navigate(
      'invoices',
      {
        screen: 'list'
      }
    );
  }

  goToTicketListPage() {
    if (!this.navigationRef.isReady()) {
      return;
    }

    this.navigationRef.navigate(
      'tickets',
      {
        screen: 'list',
      }
    );
  }

  goToTicketDetailPage(id: TicketTypes.Id) {
    if (!this.navigationRef.isReady()) {
      return;
    }

    this.navigationRef.navigate(
      'tickets',
      {
        screen: 'detail',
        params: { id }
      }
    );
  }
}