import React, { useCallback, useEffect } from 'react';
import { Box, FlatList, SectionList, Spinner, useDisclose, View } from 'native-base';
import { ISectionListProps } from 'native-base/lib/typescript/components/basic/SectionList/types';
import { FlatListProps, SectionListData, SectionListRenderItemInfo } from 'react-native';
import styled from 'styled-components';

import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import { useNavigation } from '@react-navigation/native';
import ScheduleTableErrorCell from '../molecules/ScheduleTable/ScheduleTableErrorCell';
import ScheduleTableLoading from '../molecules/ScheduleTable/ScheduleTableLoading';
import ScheduleTableNoDataCell from '../molecules/ScheduleTable/ScheduleTableNoDataCell';
import { ListRenderItemInfo } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control';

const TableContainer = styled(Box)({
  height: '100%',
  userSelect: 'none'
})

type SectionListHeaderData = {
  headerTitle: string;
};

type PropsBase<D> = {
  title: string;
  onRefresh: () => void;
  refreshing: boolean;
  isLoadingNext: boolean;
  onListEndReached: () => void;
};

type PropsFlatList<D> = PropsBase<D> & {
  type: 'flat-list'
  loadable: AsyncLoadable<ReadonlyArray<D>>;
  renderItem: (data: ListRenderItemInfo<D>) => React.ReactElement;
}

type PropsSectionList<D> = PropsBase<D> & {
  type: 'section-list'
  loadable: AsyncLoadable<ReadonlyArray<SectionListData<D, SectionListHeaderData>>>;
  renderHeader: (data: SectionListData<D, SectionListHeaderData>) => React.ReactElement;
  renderItem: (data: SectionListRenderItemInfo<D, SectionListHeaderData>) => React.ReactElement;
}

type Props<D> =
  | PropsFlatList<D>
  | PropsSectionList<D>

function InfiniteList<D>(
  props: Props<D>
): JSX.Element | null {
  if (props.loadable._type === 'error') {
    return <ScheduleTableErrorCell message={props.loadable.error} />;
  } else {
    if (props.loadable.data === undefined || props.loadable._type === 'loading' || props.refreshing) {
      return <ScheduleTableLoading />;
    } else {
      if (props.type === "section-list") {
        return <SectionList
          sections={props.loadable.data}
          renderSectionHeader={({ section }) => props.renderHeader(section)}
          renderItem={props.renderItem}
          onEndReached={props.onListEndReached}
        />
      } else {
        if (props.loadable.data?.length === 0) {
          return <ScheduleTableNoDataCell />;
        }
        return <FlatList
          data={props.loadable.data}
          renderItem={props.renderItem}
          refreshControl={<RefreshControl refreshing={props.refreshing} onRefresh={props.onRefresh} />}
          onEndReached={props.onListEndReached}
          ListFooterComponent={props.isLoadingNext ? <Box><Spinner size="lg" /></Box> : null} />
      }

    }
  }
}

export default function InfiniteListPage<D>(
  props: Props<D>,
) {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.setOptions?.({
      headerTitle: props.title
    });
  }, [props.title])

  return (
    <TableContainer background="white">
      <InfiniteList
        {...props} />
    </TableContainer>
  );
}
