import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { AsyncLoadable } from '../AsyncLoadable';
import { MarangoApiError } from '../Services/MarangoApi';

const GENERIC_ERROR_MESSAGE = 'An error occurred, Please Try Again in a moment.';

export class EntityListStore<Entity> {
  private _cache: AsyncLoadable<ReadonlyArray<Entity>> | undefined = undefined

  constructor(
    private readonly _requester: () => Promise<ReadonlyArray<Entity>>,
    private readonly _entityStoreUpdater?: (entity: Entity) => void
  ) {
    makeAutoObservable(this);
    // makeLoggable(this);
  }

  getEntities(): AsyncLoadable<ReadonlyArray<Entity>> | undefined {
    return this._cache;
  }

  setError(message: string): void {
    this._cache = {
      _type: 'error',
      error: message,
    };
  }

  setLoading(
    invalidate: boolean = false,
  ): void {
    const existingCache = this._cache;

    let newEntry: AsyncLoadable.Loading<ReadonlyArray<Entity>>;

    if (existingCache && existingCache._type !== 'error' && !invalidate) {
      newEntry = {
        _type: 'loading',
        data: existingCache.data,
      };
    } else {
      newEntry = { _type: 'loading' };
    }

    this._cache = newEntry;
  }

  setLoaded(
    entities: ReadonlyArray<Entity>,
  ): void {
    this._cache = {
      _type: 'loaded',
      lastRefresh: DateTime.now(),
      data: entities,
    };
  }

  async refreshEntities(): Promise<void> {
    this.setLoading()

    try {
      const entities = await this._requester();
      this.setLoaded(entities);

      for (const entity of entities) {
        this._entityStoreUpdater?.(entity)
      }
    } catch (e: unknown) {
      if (e instanceof MarangoApiError) {
        this.setError(e.message);
      } else {
        this.setError(
          GENERIC_ERROR_MESSAGE,
        );
      }
      console.error(e)
    }
  }
}
