import React, { useCallback, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
  Box,
  Button,
  Modal,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import { observer } from 'mobx-react-lite';

import Bubble from '../../atoms/Bubble';
import MarangoLogo from '../../atoms/MarangoLogo';

type Props = {
  onLogout: () => void;
  onChangePassword: () => void;
};

const SettingsListPage = observer<Props>(props => {
  const insets = useSafeAreaInsets();

  const logoutAlertCallback = useCallback((confirmed: boolean) => {
    setShowLogoutConfirmation(false);
    if (confirmed) {
      props.onLogout();
    }
  },
    [props.onLogout],
  );

  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  return (
    <>
      <Modal isOpen={showLogoutConfirmation}>
        <Modal.Content>
          <Modal.Header>Logout Confirmation</Modal.Header>
          <Modal.Body>
            <Text>Are you sure you want to logout?</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                onPress={() => logoutAlertCallback(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onPress={() => logoutAlertCallback(true)}>Logout</Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <ScrollView
        _contentContainerStyle={{ height: '100%' }}
        paddingBottom={insets.bottom}>
        <VStack
          flex={1}
          height="100%"
          mx="2"
          marginY="2"
          space="2"
          flexDirection="column"
          justifyContent="space-between">
          <VStack flex={1} alignItems="center" mx="5">
            <Box height="100%" maxWidth="600" width="100%">
              <VStack flex={1} justifyContent="space-between">
                <Box>
                  <MarangoLogo color="ext" compact />
                  <Box>

                  </Box>
                </Box>

                <Bubble paddingY={0} paddingX={0} marginBottom>
                  <Button.Group direction="column" isAttached variant="ghost">
                    <Button onPress={props.onChangePassword}>Change Password</Button>
                    <Button
                      colorScheme="red"
                      onPress={() => setShowLogoutConfirmation(true)}>
                      Logout
                    </Button>
                  </Button.Group>
                </Bubble>
              </VStack>
            </Box>
          </VStack>
        </VStack>
      </ScrollView>
    </>
  );
});

export default SettingsListPage;
