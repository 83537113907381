import React from 'react';
import { Center } from 'native-base';
import Entypo from 'react-native-vector-icons/Entypo';

import assertNever from '@marango/api/src/utils/assertNever';
import { FreightJobDetailTypes } from '@marango/api/src/models/FreightJob/FreightJobDetail';
import { Colors } from '../../../Colors';

type Props = {
  detailType: FreightJobDetailTypes.Type;
  small?: boolean;
};

const JobScheduleDetailIcon = (props: Props) => {
  let color: string;
  let iconName: string;
  let iconColor: string;
  switch (props.detailType) {
    case 'D': {
      color = Colors.ORANGE.rgb().string();
      iconName = 'arrow-bold-down';
      iconColor = Colors.BLACK.alpha(0.5).rgb().string();
      break;
    }
    case 'P': {
      color = Colors.BLUE.rgb().string();
      iconName = 'arrow-bold-up';
      iconColor = Colors.WHITE.alpha(0.5).rgb().string();
      break;
    }
    default:
      assertNever(props.detailType);
  }

  const iconSize = props.small ? 12 : 22;
  const padding = props.small ? 0 : 1;
  const bgColor = props.small ? undefined : color;

  return (
    <Center bg={bgColor} borderRadius={iconSize} p={padding} style={{ aspectRatio: 1 }}>
      <Entypo name={iconName} size={iconSize} color={iconColor} />
    </Center>
  );
};

export default JobScheduleDetailIcon;
