import { NavigationStore } from "@marango/api/src/stores/NavigationStore";
import { RootStore } from "@marango/api/src/stores/RootStore";
import { useGrowerRootStore } from "./GrowerPortal/GrowerRootStoreProvider";

export function useRootStore(): RootStore {
  // Eventually check for Freight Root Store here too
  const store = useGrowerRootStore()

  if (!store) {
    throw new Error('Root Store not found')
  }

  return store
}