import React, { useCallback, useEffect, useState } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import { StackScreenProps } from '@react-navigation/stack';

import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import ContractDetailPage from '../../Components/pages/Grower/ContractDetailPage';
import { ContractsParamList } from './GrowerRootStackNavigator';
import { useGrowerRootStore } from '../../Stores/GrowerPortal/GrowerRootStoreProvider';
import { Contract } from '@marango/api/src/models/Grower/Contract';

type Props = StackScreenProps<ContractsParamList, 'detail'>;

const ContractDetailPageContainer = observer<Props>(props => {
  const { id } = props.route.params;
  const { contractStore } = useGrowerRootStore();

  const dataState = contractStore.getEntity(id);

  const onRefresh = useCallback(() => {
    contractStore.refreshEntityById(id)
  }, [id]);

  // Start a request to refresh the DateRange if no data exists
  useEffect(() => {
    if (!dataState) {
      onRefresh()
    }
  }, [onRefresh, dataState]);

  const data: AsyncLoadable<Contract> = dataState ?? AsyncLoadable.loading();

  return <ContractDetailPage
    contract={data}
    onRefresh={onRefresh}
  />
});

export default ContractDetailPageContainer;
