import { IsISO8601, IsNotEmpty, IsString } from 'class-validator';
import { ISO8601String } from '../models/ISO8601String';
import { MarangoApi } from '../Services/MarangoApi';

export class MarangoLoginResponseDTO {
  @IsString()
  @IsNotEmpty()
  public token: MarangoApi.BearerToken;

  @IsISO8601()
  public expires: ISO8601String;

  constructor(token: string, expires: string) {
    this.token = token as MarangoApi.BearerToken;
    this.expires = expires as ISO8601String;
  }
}
