import * as _ from 'lodash';
import { AddressString } from '../models/Address';

export function addressFormatter(
  ...addressComponents: ReadonlyArray<string>
): AddressString {
  return addressComponents
    .map(s => _.startCase(_.toLower(s)))
    .join(', ') as AddressString;
}
