import { DateTime, Interval } from 'luxon';
import { Commodity, Units } from './Commodity';
import { FreightJobDetail } from './FreightJobDetail';
import { FreightJobTypes } from './Types';

export class FreightJob {
  public constructor(
    readonly jobId: FreightJobTypes.Id,
    readonly jobNumber: FreightJobTypes.Number,
    readonly details: ReadonlyArray<FreightJobDetail>,
    readonly commodity: Commodity,
    readonly units: Units,
  ) {}

  get startDate(): DateTime {
    const earliestDetail = this.details.reduce((current, next) => {
      return current.startDateTime > next.startDateTime ? next : current;
    }).startDateTime;

    return earliestDetail.startOf('day');
  }

  get endDate(): DateTime {
    const latestDetail = this.details.reduce((current, next) => {
      return current.latestDateTime < next.latestDateTime ? next : current;
    }).latestDateTime;

    return latestDetail.endOf('day');
  }

  isDateWithinJob(date: DateTime): boolean {
    const interval = Interval.fromDateTimes(this.startDate, this.endDate);
    return interval.contains(date);
  }

  static mock(
    jobId: number,
    jobNum: string,
    details: ReadonlyArray<FreightJobDetail>,
    commodity: Commodity,
    units: Units,
  ): FreightJob {
    return new FreightJob(
      jobId as FreightJobTypes.Id,
      jobNum as FreightJobTypes.Number,
      details,
      commodity,
      units,
    );
  }
}
