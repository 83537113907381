import React from 'react';
import { DateTime } from 'luxon';

import DateFormatter from '@marango/api/src/formatters/dateFormatter';

import ScheduleTableCellContainer from './ScheduleTableCellContainer';
import Label from '../../atoms/Label';
import { Colors } from '../../../Colors';

const JobScheduleDateRow = (props: { date: DateTime }) => (
  <ScheduleTableCellContainer color="LIGHT_GREY" alpha={1} compact>
    <Label color={Colors.DARK_GREY.string()} variant="body">
      {DateFormatter(props.date, 'friendlyFull')}
    </Label>
  </ScheduleTableCellContainer>
);

export default JobScheduleDateRow;
