import React from 'react';
import { Colors } from '../../../Colors';
import Label from '../../atoms/Label';
import ScheduleTableCellContainer from './ScheduleTableCellContainer';

const ScheduleTableNoDataCell = () => (
  <ScheduleTableCellContainer compact>
    <Label variant="minor" textAlign="center" color={Colors.DARK_GREY.string()}>
      No data available
    </Label>
  </ScheduleTableCellContainer>
);

export default ScheduleTableNoDataCell;
