import React, { useCallback, useState } from 'react';
import { Center, VStack } from 'native-base';
import { Controller, useForm } from 'react-hook-form';
import zxcvbn from 'zxcvbn';

import InputFormButton from '../atoms/Login/InputFormButton';
import LoginTextInput from '../molecules/LoginTextInput';
import PasswordStrengthMeter from '../molecules/PasswordStrengthMeter';
import Label from '../atoms/Label';
import { Colors } from '../../Colors';

const NEW_PASSWORD_MINIMUM_ERROR_MESSAGE = "New Password must be at least 8 characters long";
const NEW_PASSWORD_SECURITY_ERROR_MESSAGE = "Password must be stronger, try using a combination of A-Z, a-z, 0-9, !@#$%()[]";

interface Props {
  currentPasswordError?: string
  newPasswordError?: string
  onSubmit: (details: ChangePasswordForm.RequestDetails) => void;
  isLoading?: boolean
}

export function ChangePasswordForm(props: Props) {
  const { control, handleSubmit, formState: { isSubmitting, isValid }, getValues } = useForm<ChangePasswordForm.RequestDetails>({ mode: 'onChange', criteriaMode: "all" })
  const [passwordScore, setPasswordScore] = useState<0 | 1 | 2 | 3 | 4>(0)

  const validateNewPassword = useCallback((newPassword?: string) => {
    if (!newPassword) {
      return
    }

    const result = zxcvbn(newPassword)
    setPasswordScore(result.score)

    if (result.score <= 2) {
      return NEW_PASSWORD_SECURITY_ERROR_MESSAGE
    } else {
      return undefined
    }
  }, [setPasswordScore])

  const confirmPasswordValidator = useCallback(() => {
    const { newPassword, confirmPassword } = getValues()
    if (newPassword !== confirmPassword) {
      return "Passwords must match!"
    }

    return true
  }, [getValues])

  const editable = !isSubmitting && props.isLoading === false;

  return (
    <VStack space="3" borderRadius="20" padding="5" backgroundColor={Colors.LIGHT_GREY.hex()}>
      <Center paddingBottom="50px">
        <Label bold variant="jumboHeader">Change Password</Label>
      </Center>

      <Controller
        control={control}
        name='currentPassword'
        rules={{ required: "Current password is required." }}
        render={({ field, fieldState: { error } }) => <LoginTextInput
          description="Current Password"
          value={field.value ?? ""}
          onChange={field.onChange}
          onBlur={field.onBlur}
          editable={editable}
          textContentType="password"
          clearButtonMode="always"
          importantForAutofill="no"
          secureTextEntry={true}
          error={error?.message ?? props.currentPasswordError}
        />}
      />

      <Controller
        control={control}
        name='newPassword'
        rules={{ required: NEW_PASSWORD_MINIMUM_ERROR_MESSAGE, minLength: { value: 8, message: NEW_PASSWORD_MINIMUM_ERROR_MESSAGE }, validate: validateNewPassword }}
        render={({ field, fieldState: { error } }) => <LoginTextInput
          description="New Password"
          value={field.value ?? ""}
          onChange={field.onChange}
          onBlur={field.onBlur}
          editable={editable}
          textContentType="password"
          clearButtonMode="always"
          importantForAutofill="no"
          secureTextEntry={true}
          error={error?.message ?? props.newPasswordError}
        />}
      />

      <PasswordStrengthMeter strength={passwordScore} />

      <Controller
        control={control}
        name='confirmPassword'
        rules={{ validate: confirmPasswordValidator, deps: [] }}
        render={({ field, fieldState: { error } }) => <LoginTextInput
          description="Confirm New Password"
          value={field.value ?? ""}
          onChange={field.onChange}
          onBlur={field.onBlur}
          editable={editable}
          textContentType="password"
          clearButtonMode="always"
          importantForAutofill="no"
          secureTextEntry={true}
          error={error?.message}
        />}
      />

      <Center mt="2">
        <InputFormButton disabled={!isValid || !editable} onPress={handleSubmit(props.onSubmit)}>
          Change Password
        </InputFormButton>
      </Center>
    </VStack>
  );
}
export namespace ChangePasswordForm {
  export type RequestResponse =
    | { _type: 'validationError'; message: string }
    | { _type: 'validated'; details: RequestDetails };

  export type RequestDetails = {
    readonly currentPassword: string;
    readonly newPassword: string;
    readonly confirmPassword: string;
  };
}
