import isWhole from '../utils/isWhole';

export default function numberWithSuffix(number: number | string): string {
  const parsedNumber =
    typeof number === 'string' ? parseInt(number, 10) : number;

  // Only modify valid whole numbers
  if (!isFinite(parsedNumber) || !isWhole(parsedNumber)) {
    return `${number}`;
  }

  const numberString = `${parsedNumber}`;
  if (parsedNumber >= 11 && parsedNumber <= 19) {
    return `${number}th`;
  } else if (numberString.endsWith('1')) {
    return `${number}st`;
  } else if (numberString.endsWith('2')) {
    return `${number}nd`;
  } else if (numberString.endsWith('3')) {
    return `${number}rd`;
  } else {
    return `${number}th`;
  }
}
