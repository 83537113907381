import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { extendTheme, NativeBaseProvider, StatusBar, Theme } from 'native-base';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Colors } from './Colors';
import { NavigationContainer } from '@react-navigation/native';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

export function withProviders(
  component: React.ReactNode,
  includeNavigation: boolean = false,
) {
  const config = {
    dependencies: {
      'linear-gradient': require('react-native-linear-gradient').default,
    },
  };

  const theme = extendTheme({
    colors: {
      green: Colors.makeNativeBaseColors('GREEN'),
    },
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
  } as Theme);

  const providers = (
    <SafeAreaProvider>
      <NativeBaseProvider config={config} theme={theme}>
        <QueryClientProvider client={queryClient}>
          <>
            <StatusBar
              barStyle="dark-content"
              backgroundColor="transparent"
              translucent
            />

            {component}
          </>
        </QueryClientProvider>
      </NativeBaseProvider>
    </SafeAreaProvider>
  );

  return includeNavigation ? (
    <NavigationContainer linking={{
      prefixes: [],
      config: {
        screens: {
          Login: { path: 'login' },
          Home: { path: 'home' },
        }
      }
    }}>{providers}</NavigationContainer>
  ) : (
    providers
  );
}
