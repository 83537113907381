import _ from 'lodash';
import { Type } from 'class-transformer';
import {
  IsArray,
  IsDate,
  IsDateString,
  IsOptional,
  IsNumber,
  IsString,
  ValidateNested,
} from 'class-validator';

import { ToModel } from '../models/ToModel';
import { ContractTypes, RCTITypes } from '../models/Grower/Types';
import { DateString, dateStringToLuxon } from '../models/DateString';
import { RCTI } from '../models/Grower/RCTI';

export class MarangoRCTIsResponseDTO
  implements ToModel<ReadonlyArray<RCTI>>
{
  @IsArray()
  @ValidateNested()
  @Type(_ => MarangoRCTIsResponseRCTIDTO)
  result!: ReadonlyArray<MarangoRCTIsResponseRCTIDTO>;

  toModel(): ReadonlyArray<RCTI> {
    return this.result.map(j => j.toModel());
  }
}

export class MarangoRCTIsResponseRCTIDTO implements ToModel<RCTI> {
  @IsString()
  invoice_no!: RCTITypes.Number;

  @IsString()
  invoice_date!: DateString;

  @IsString()
  due_date!: DateString;

  @IsNumber()
  invoice_amount!: RCTITypes.Amount;

  @IsString()
  @IsOptional()
  paid_date!: DateString | null;

  toModel(): RCTI {
    return new RCTI(
      this.invoice_no,
      this.invoice_date,
      this.due_date,
      this.invoice_amount,
      this.paid_date ?? undefined
    );
  }
}
