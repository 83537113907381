import React from 'react';
import { Box, Center, HStack, VStack } from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';

import { RCTI } from '@marango/api/src/models/Grower/RCTI';
import { CurrencyFormatter } from '@marango/api/src/formatters/currencyFormatter';
import Label from '../../atoms/Label';
import { Colors } from '../../../Colors';
import { Icon } from '../../atoms/Icon';
import { IconWrapper } from '../../atoms/IconWrapper';

const ScheduleTableInvoiceDetailCell = (props: { rcti: RCTI }) => {
  const amountColor: ColorType = props.rcti.paid ? 'green.500' : 'red.500';
  return (
    <Box
      shadow="3"
      borderRadius="10"
      p="2"
      backgroundColor={Colors.LIGHT_GREY.alpha(0.5).rgb().string()}>
      <VStack>
        <HStack>
          <Box flexBasis="33.33%">
            <IconWrapper>
              <Icon icon="invoice" />
            </IconWrapper>
          </Box>
          <Box flexBasis="33.33%">
            <Center paddingBottom={2}>
              <Label bold>RCTI # - {props.rcti.invoiceNo}</Label>
            </Center>
          </Box>
          <Box flexBasis="33.33%" />
        </HStack>
        <HStack flex={1} justifyContent="space-between">
          <VStack>
            <Label>Due: {props.rcti.dueDate}</Label>
            <Label>Received: {props.rcti.invoiceDate}</Label>
          </VStack>
          <Box flex={1}>
            <VStack
              height="100%"
              justifyContent="space-between"
              alignItems="flex-end">
              <Label color={amountColor} bold>
                {CurrencyFormatter(props.rcti.invoiceAmount)}
              </Label>

              <Label>{props.rcti.paidDate ?
                `Paid: ${props.rcti.paidDate}` :
                'Unpaid'
              }
              </Label>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default ScheduleTableInvoiceDetailCell;
