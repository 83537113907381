import { Box, VStack } from 'native-base'
import React, { PropsWithChildren } from 'react'

type Props = {
  fullHeight?: boolean
}

const InputFormContainer = (props: PropsWithChildren<Props>) => {
  return (
    <VStack flex={1} alignItems="center" mx="5">
      <Box height={props.fullHeight ? "100%" : undefined} maxWidth="600" width="100%">
        {props.children}
      </Box>
    </VStack>
  )
}

export default InputFormContainer