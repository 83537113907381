import { Box } from 'native-base';
import React from 'react';

import { Colors } from '../../Colors';
import assertNever from '@marango/api/src/utils/assertNever';
import Label from './Label';

type DialogSeverity = 'error' | 'warning' | 'success';

function dialogSeverityToColour(severity: DialogSeverity): string {
  switch (severity) {
    case 'error':
      return Colors.RED.lighten(0.1).string();
    case 'warning':
      return Colors.rgba('ORANGE');
    case 'success':
      return Colors.rgba('GREEN');
    default:
      assertNever(severity);
  }
}

type Props = {
  title: string;
  description: string;
  severity: DialogSeverity;
};

const DialogBox = (props: Props) => {
  return (
    <Box
      px="1"
      py="3"
      borderRadius="20"
      bg={dialogSeverityToColour(props.severity)}>
      <Box mb="1.5">
        <Label variant="header" bold textAlign="center">
          {props.title}
        </Label>
      </Box>
      <Label variant="body" wrap textAlign="center">
        {props.description}
      </Label>
    </Box>
  );
};

export default DialogBox;
