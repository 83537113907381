import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { AsyncLoadable } from '../AsyncLoadable';
import { DateRangeString } from '../models/DateRangeSring';
import { FreightJob } from '../models/FreightJob/FreightJob';
import { MarangoApiError } from '../Services/MarangoApi';
import { DateRange } from '../time/dateRange';
import { EntityStore } from './EntityStore';

const GENERIC_ERROR_MESSAGE = 'An error occurred, Please Try Again in a moment.';


export class DateRangeEntityStore<Entity> {
  private _rangeCache: Record<
    DateRangeString,
    AsyncLoadable<ReadonlyArray<Entity>> | undefined
  > = {};

  constructor(
    private readonly _requester: (dateRange: DateRange) => Promise<ReadonlyArray<Entity>>,
    private readonly _entityStoreUpdater: (entity: Entity) => void
  ) {
    makeAutoObservable(this);
    // makeLoggable(this);
  }

  getEntitiesForRange(
    range: DateRange,
  ): AsyncLoadable<ReadonlyArray<Entity>> | undefined {
    const dateRangeString = DateRangeString.fromDateRange(range);
    return this._rangeCache[dateRangeString];
  }

  setRangeError(dateRangeString: DateRangeString, message: string): void {
    this._rangeCache[dateRangeString] = {
      _type: 'error',
      error: message,
    };
  }

  setRangeLoading(
    dateRangeString: DateRangeString,
    invalidate: boolean = false,
  ): void {
    const existingRange = this._rangeCache[dateRangeString];

    let newEntry: AsyncLoadable.Loading<ReadonlyArray<Entity>>;

    if (
      existingRange !== undefined &&
      existingRange._type !== 'error' &&
      !invalidate
    ) {
      newEntry = {
        _type: 'loading',
        data: existingRange.data,
      };
    } else {
      newEntry = { _type: 'loading' };
    }

    this._rangeCache[dateRangeString] = newEntry;
  }

  setRangeLoaded(
    dateRangeString: DateRangeString,
    jobs: ReadonlyArray<Entity>,
  ): void {
    this._rangeCache[dateRangeString] = {
      _type: 'loaded',
      lastRefresh: DateTime.now(),
      data: jobs,
    };
  }

  async refreshEntitiesForDateRange(
    dateRange: DateRange
  ): Promise<void> {
    const dateRangeString = DateRangeString.fromDateRange(dateRange);
    this.setRangeLoading(dateRangeString);

    try {
      const entities = await this._requester(dateRange);
      this.setRangeLoaded(dateRangeString, entities);

      for (const entity of entities) {
        this._entityStoreUpdater(entity)
      }
    } catch (e: unknown) {
      if (e instanceof MarangoApiError) {
        this.setRangeError(dateRangeString, e.message);
      } else {
        this.setRangeError(
          dateRangeString,
          GENERIC_ERROR_MESSAGE,
        );
      }
    }
  }
}
