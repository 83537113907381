import { DateTime } from 'luxon';
import { DateRange } from '../time/dateRange';

/** A set of Start & End Date Strings in the format `{startDateString}_{endDateString}` or `1995-01-15_1995-01-21` */
export type DateRangeString = NominalPrimative<string, 'DateRangeString'>;

export namespace DateRangeString {
  export function toDateRange(dateStringRange: DateRangeString): DateRange {
    const dates = dateStringRange.split('_');
    return {
      start: DateTime.fromFormat(dates[0], 'y-MM-dd'),
      end: DateTime.fromFormat(dates[1], 'y-MM-dd'),
    };
  }

  export function fromDateRange(dateRange: DateRange): DateRangeString {
    const startString = dateRange.start.toFormat('y-MM-dd');
    const endString = dateRange.end.toFormat('y-MM-dd');
    return `${startString}_${endString}` as DateRangeString;
  }
}
