export namespace UserLoginDetails {
  export type ServerId = NominalPrimative<string, 'UserDetails.ServerId'>;
  export type Username = NominalPrimative<string, 'UserDetails.Username'>;
  export type Password = NominalPrimative<string, 'UserDetails.Password'>;
  export type RememberMe = NominalPrimative<boolean, 'UserDetails.RememberMe'>;

  export function isUserLoginDetails(obj: unknown): obj is UserLoginDetails {
    return (
      typeof obj === 'object' &&
      typeof (obj as UserLoginDetails).serverId === 'string' &&
      typeof (obj as UserLoginDetails).username === 'string' &&
      typeof (obj as UserLoginDetails).password === 'string'
    );
  }
}

export type UserLoginDetails = {
  serverId: UserLoginDetails.ServerId;
  username: UserLoginDetails.Username;
  password: UserLoginDetails.Password;
  rememberMe?: UserLoginDetails.RememberMe;
};
