import { DateTime } from 'luxon';
import numberWithSuffix from './numberWithSuffix';

export type DateFormatterStyle =
  | 'friendly'
  | 'friendlyFull'
  | 'complete'
  | 'completeNoYear'
  | '12hrTime'
  | 'dayInt'
  | 'dayMonth'
  | 'monthYear';

export default function DateFormatter(
  date: DateTime,
  style: DateFormatterStyle = 'friendly',
): string {
  switch (style) {
    case 'friendly': {
      const weekDay = date.toFormat('EEEE');
      const dayWithSuffix = numberWithSuffix(date.toFormat('d'));
      const month = date.toFormat('MMMM');
      return `${weekDay}, ${dayWithSuffix} ${month}`;
    }
    case 'friendlyFull': {
      return date.toFormat('EEEE dd MMMM y');
    }
    case 'complete': {
      return date.toFormat('dd/LL/y');
    }
    case 'completeNoYear': {
      return date.toFormat('dd/LL');
    }
    case '12hrTime': {
      return date.toFormat('t');
    }
    case 'dayInt': {
      return date.toFormat('dd');
    }
    case 'dayMonth': {
      return date.toFormat('d/L');
    }
    case 'monthYear': {
      return date.toFormat('LLLL - y')
    }
  }
}
