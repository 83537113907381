import { Box, Switch, View} from 'native-base';
import React from 'react';
import { StyleSheet } from 'react-native';
import Label from '../atoms/Label';

type Props = {
  description: string;
  disabled: boolean;
  onValueChange?: (value: boolean) => void;
  value: boolean;
};

export const LoginSwitchInput = (props: Props) => {
  return (
    <View style={stylesheet.container}>
      <Box marginLeft={1}>
        <Label variant="inputLabel">{props.description}</Label>
      </Box>
      <Switch
        disabled={props.disabled}
        value={props.value}
        onValueChange={props.onValueChange}
      />
    </View>
  );
};

const stylesheet = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default LoginSwitchInput;
