import { Box, Progress } from 'native-base';
import React, { useMemo } from 'react'
import { Colors } from '../../Colors';
import Label from '../atoms/Label';

type Props = {
  strength: 0 | 1 | 2 | 3 | 4
}

function getLabelValues(strength: Props['strength']): [label: string, progress: number, color: string] {
  switch (strength) {
    case 0: return [ 'Weak', 0, '' ];
    case 1: return [ 'Weak', 25, Colors.RED.hex() ];
    case 2: return [ 'Fair', 50, Colors.ORANGE.hex() ];
    case 3: return [ 'Good', 75, Colors.YELLOW.hex() ];
    case 4: return [ 'Strong', 100, Colors.GREEN.hex() ];
  }
}

const PasswordStrengthMeter = (props: Props) => {
  const { strength } = props;
  const [ label, progress, color ] = useMemo(() => getLabelValues(strength), [strength])

  return (
    <Box>
      <Progress
        marginBottom="2"
        value={progress}
        focusable={false}
        _filledTrack={{ backgroundColor: color }}
        backgroundColor="white"
        size="md"
      />
      <Label bold wrap variant="body">Password Strength:</Label><Label variant='body'>{label}</Label>
    </Box>
  )
}

export default PasswordStrengthMeter