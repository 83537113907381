import { Box } from "native-base";
import styled from "styled-components";

export const IconWrapper = styled(Box)({
  height: '25px',
  width: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
