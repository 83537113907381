import React from 'react';
import { Box } from 'native-base';

import DialogBox from '../../atoms/DialogBox';

const ScheduleTableErrorCell = (props: { message: string }) => {
  return (
    <Box m="1">
      <DialogBox
        severity="error"
        title="We ran into an error loading that"
        description={props.message}
      />
    </Box>
  );
};

export default ScheduleTableErrorCell;
