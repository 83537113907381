import _ from 'lodash';
import { Type } from 'class-transformer';
import {
  IsArray,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

import { ToModel } from '../models/ToModel';
import { CommodityTypes, WarehouseTypes } from '../models/Grower/Types';
import { Warehouse, WarehouseLevel } from '../models/Grower/Warehouse';
import { MarangoApiResponseCommodityDTO, MarangoApiResponseCommodityGradeDTO, MarangoApiResponseUnitDTO } from './MarangoJobsResponseDTO';

export class MarangoWarehouseSitesResponseDTO
  implements ToModel<ReadonlyArray<Warehouse>>
{
  @IsArray()
  @ValidateNested()
  @Type(_ => MarangoWarehousesResponseSiteDTO)
  result!: ReadonlyArray<MarangoWarehousesResponseSiteDTO>;

  toModel(): ReadonlyArray<Warehouse> {
    return this.result.map(j => j.toModel());
  }
}

export class MarangoWarehousesResponseSiteDTO implements ToModel<Warehouse> {
  @IsString()
  site_name!: WarehouseTypes.Name;

  @IsArray()
  @ValidateNested()
  @Type(_ => MarangoWarehousesResponseSiteLevelDTO)
  levels!: ReadonlyArray<MarangoWarehousesResponseSiteLevelDTO>

  toModel(): Warehouse {
    return new Warehouse(
      this.site_name,
      [...this.levels].map(l => l.toModel())
    );
  }
}

export class MarangoWarehousesResponseSiteLevelDTO implements ToModel<WarehouseLevel> {
  @IsNumber()
  amount!: WarehouseTypes.Level.Amount

  @IsString()
  commodity_season!: CommodityTypes.Season

  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityDTO)
  commodity!: MarangoApiResponseCommodityDTO;

  @IsOptional()
  @ValidateNested()
  @Type(_ => MarangoApiResponseCommodityGradeDTO)
  commodity_grade!: MarangoApiResponseCommodityGradeDTO | null;

  @ValidateNested()
  @Type(_ => MarangoApiResponseUnitDTO)
  unit!: MarangoApiResponseUnitDTO;

  toModel(): WarehouseLevel {
    return new WarehouseLevel(
      this.commodity_season,
      this.commodity.getCommodityName(),
      this.commodity_grade?.getCommodityGrade(),
      _.startCase(_.toLower(this.unit.multiple_name)),
      this.amount
    );
  }

}