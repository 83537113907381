import React, { forwardRef, MutableRefObject } from 'react';
import { TextInputProps } from 'react-native';

import Label from '../atoms/Label';
import { Box, Input } from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';
import { Colors } from '../../Colors';

type Props =
  {
    description: string;
    editable?: boolean;
    error?: string;
  }
  & TextInputProps
  & Required<Pick<TextInputProps, 'value'>>;

export const LoginTextInput = (props: Props) => {
  const inputVariant = props.editable === true ? 'unstyled' : 'filled';
  const color: ColorType = props.error === undefined ? "black" : "red.500";

  return (
    <Box>
      <Box pl={1}>
        <Label variant="inputLabel" color={color}>{props.description}</Label>
      </Box>
      <Box bg="white" borderRadius={10}>
        <Input
          {...props}
          color={color}
          editable={props.editable}
          variant={inputVariant}
          isFullWidth
          fontSize="lg"
          />
      </Box>
      { props.error && <Label variant="body" textAlign="right" wrap color={Colors.RED.hex()}>{props.error}</Label> }
    </Box>
  );
};

export default LoginTextInput;
