import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Box, Button, Modal, Text, useDisclose, VStack } from "native-base";

import DialogBox from "../../atoms/DialogBox";
import { ChangePasswordForm } from "../../organism/ChangePasswordForm";

type Props = {
  onSubmit: (request: ChangePasswordForm.RequestDetails) => void | Promise<void>;
  currentPasswordError?: string;
  serverError?: string;
  isLoading?: boolean;
  showSuccessAlert: boolean;
  onSuccessAlertConfirm: () => void;
};

const ChangePasswordPage = (props: Props) => {
  const safeAreaInsets = useSafeAreaInsets();

  return (
    <>
      <Modal isOpen={props.showSuccessAlert}>
        <Modal.Content>
          <Modal.Header>Password updated</Modal.Header>
          <Modal.Body>
            <Text>Your Password was successfully updated.</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button colorScheme="green" onPress={props.onSuccessAlertConfirm}>Ok</Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <VStack height="100%" mx="2">
        <KeyboardAwareScrollView
          contentContainerStyle={{
            flex: 1
          }}
          enableAutomaticScroll
          enableOnAndroid>
          <Box height={safeAreaInsets.top} />

          <VStack height="100%" alignItems="center" justifyContent="center" mx="5">
            <Box maxWidth="600" width="100%">
              <ChangePasswordForm isLoading={props.isLoading} onSubmit={props.onSubmit} currentPasswordError={props.currentPasswordError} />

              {props.serverError && (
                <Box mt="5">
                  <DialogBox
                    title="Error Updating Password"
                    description={props.serverError}
                    severity="error"
                  />
                </Box>
              )}
            </Box>
          </VStack>
        </KeyboardAwareScrollView>
      </VStack>
    </>
  );
};

export default ChangePasswordPage;
