import React, { useCallback, useState } from 'react';
import { VStack, HStack, Box, Pressable, Center } from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';

import Label from '../../atoms/Label';
import { Colors } from '../../../Colors';
import { Icon } from '../../atoms/Icon';
import { Contract } from '@marango/api/src/models/Grower/Contract';
import DateFormatter from '@marango/api/src/formatters/dateFormatter';
import { IconWrapper } from '../../atoms/IconWrapper';
interface Props {
  contract: Contract;
  onSelect: (contract: Contract) => void;
}

const ScheduleTableContractDetailCell = (props: Props) => {
  const [bgColor, setBgColor] = useState<ColorType | undefined>();

  const onPressIn = useCallback(() => {
    setBgColor(Colors.DARK_GREY.alpha(0.5).rgb().string());
  }, [setBgColor]);

  const onHoverIn = useCallback(() => {
    setBgColor(Colors.DARK_GREY.alpha(0.2).rgb().string());
  }, [setBgColor]);

  const onPressOut = useCallback(() => {
    setBgColor(undefined);
  }, [setBgColor]);

  const onSelect = useCallback(() => {
    props.onSelect(props.contract);
  }, [props.onSelect]);

  return (
    <Pressable
      bgColor={bgColor}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      onHoverIn={onHoverIn}
      onHoverOut={onPressOut}
      onPress={onSelect}>
      <Box
        shadow="3"
        borderRadius="10"
        p="2"
        backgroundColor={Colors.GREEN.lighten(0.8).hex()}>
        <VStack alignItems="stretch" justifyContent="stretch">
          <HStack alignItems="center" justifyContent="stretch" flex={1}>
            <Box flexBasis="33.33%">
              <IconWrapper>
                <Icon icon="contract" />
              </IconWrapper>
            </Box>
            <Box flexBasis="33.33%">
              <Label textAlign="center" bold>
                {props.contract.contractNo}
              </Label>
            </Box>
            <Box flexBasis="33.33%">
              <Label textAlign="end">
                {DateFormatter(props.contract.timestamp, 'complete')}
              </Label>
            </Box>
          </HStack>

          <HStack justifyContent="space-between">
            <Box py="1">
              <Label>Commodity: {props.contract.commodityKey}</Label>
              <Label>Season: {props.contract.commoditySeason}</Label>
            </Box>
            <Box justifyContent="center">
              <Box paddingLeft="2.5">
                <Icon icon="chevronRight" />
              </Box>
            </Box>
          </HStack>

          <HStack flexGrow={1} justifyContent="space-between">
            <VStack textAlign="left" flexBasis="33.33%">
              <Label bold>Contracted</Label>
              <Label>
                {props.contract.unitsContracted.toFixed(2)}{' '}
                {props.contract.unitStringShort}
              </Label>
            </VStack>

            <VStack textAlign="center" flexBasis="33.33%">
              <Label bold>Delivered</Label>
              <Label>
                {props.contract.unitsDelivered?.toFixed(2) ?? '0.00'} {props.contract.unitStringShort}
              </Label>
            </VStack>

            <VStack textAlign="right" flexBasis="33.33%">
              <Label bold>Price</Label>
              <Label>
                ${props.contract.basePricePerUnit.toFixed(2)}/
                {props.contract.unitStringShort}
              </Label>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    </Pressable>
  );
};

export default ScheduleTableContractDetailCell;
