import Color from 'color';

const ColorsDict = {
  // Marango Style Guide Colors
  BLUE: Color('#0069A3'),
  PURPLE: Color('#6D3180'),
  GREEN: Color('#81B33B'),
  GREY: Color('#B5B5B5'),
  RED: Color('#D94649'),
  AQUA: Color('#36AC8A'),
  ORANGE: Color('#F99D25'),


  // Addd Colors
  YELLOW: Color('#f9e125'),

  BACKGROUND_BLUE: Color('#E6EDF1'),
  DARK_GREY: Color('#484948'),
  LIGHT_GREY: Color('#DDDDDD'),
  WHITE: Color('#FFF'),
  BLACK: Color('#000'),
};

export type MarangoColor = keyof typeof ColorsDict;

type NativeBaseColors = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

function makeNativeBaseColors(color: MarangoColor): NativeBaseColors {
  return {
    '50': ColorsDict[color].lighten(0.5).rgb().string(),
    '100': ColorsDict[color].lighten(0.4).rgb().string(),
    '200': ColorsDict[color].lighten(0.3).rgb().string(),
    '300': ColorsDict[color].lighten(0.2).rgb().string(),
    '400': ColorsDict[color].lighten(0.1).rgb().string(),
    '500': ColorsDict[color].rgb().string(),
    '600': ColorsDict[color].darken(0.1).rgb().string(),
    '700': ColorsDict[color].darken(0.2).rgb().string(),
    '800': ColorsDict[color].darken(0.3).rgb().string(),
    '900': ColorsDict[color].darken(0.4).rgb().string(),
  };
}

export const Colors = {
  ...ColorsDict,
  makeNativeBaseColors,

  rgba: (color: MarangoColor): string => ColorsDict[color].rgb().string(),
  hex: (color: MarangoColor): string => ColorsDict[color].hex(),
};
