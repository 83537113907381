import React, { useCallback, useState } from 'react';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { Box, Center, VStack } from 'native-base';

import InputFormButton from '../atoms/Login/InputFormButton';
import LoginSwitchInput from '../molecules/LoginSwitchInput';
import LoginTextInput from '../molecules/LoginTextInput';

const VALIDATION_ERROR_MESSAGE =
  'Make sure you have provided a Server ID, Username & Password';

const VALIDATION_ERROR_MESSAGE_NO_SERVER_ID =
  'Make sure you have provided a Username & Password';
interface Props {
  hideServerId?: boolean;
  serverId?: string;
  username?: string;
  isLoading: boolean;
  onLoginRequest: (
    requestOrError: LoginForm.RequestResponse,
  ) => Promise<void> | void;
}

const numbersRegex = /^[0-9]*$/;

export function LoginForm(props: Props) {
  const [serverIdInputValue, setServerIdInputValue] = useState<string>(
    props.serverId ?? '',
  );
  const [usernameInputValue, setUsernameInputValue] = useState<string>(
    props.username ?? '',
  );
  const [passwordInputValue, setPasswordInputValue] = useState<string>('');
  const [rememberLoginDetails, setRememberLoginDetails] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  const rememberMeCallback = useCallback(() => {
    setRememberLoginDetails(!rememberLoginDetails);
  }, [rememberLoginDetails, setRememberLoginDetails]);

  const serverIdCallback = useCallback(
    (event: NativeSyntheticEvent<TextInputChangeEventData>) => {
      if (numbersRegex.test(event.nativeEvent.text)) {
        setServerIdInputValue(event.nativeEvent.text);
      }
    },
    [setServerIdInputValue],
  );

  const loginCallback = useCallback(async () => {
    setCanSubmit(false);

    let details: LoginForm.RequestDetails = {
      serverId: serverIdInputValue,
      username: usernameInputValue,
      password: passwordInputValue,
      rememberMe: rememberLoginDetails,
    };
    let response: LoginForm.RequestResponse;
    if (LoginForm.validateRequest(details)) {
      response = { _type: 'validated', details };
    } else {
      response = {
        _type: 'validationError',
        message: props.hideServerId ? VALIDATION_ERROR_MESSAGE_NO_SERVER_ID : VALIDATION_ERROR_MESSAGE,
      };
    }

    await props.onLoginRequest(response);
    setCanSubmit(true);
  }, [
    serverIdInputValue,
    usernameInputValue,
    passwordInputValue,
    rememberLoginDetails,
    props,
  ]);

  return (
    <VStack space="3">
      {!props.hideServerId && (
        <LoginTextInput
          description="Server ID"
          value={`${serverIdInputValue ?? ''}`}
          onChange={serverIdCallback}
          editable={canSubmit}
          keyboardType="numeric"
          clearButtonMode="always"
          importantForAutofill="yes"
        />
      )}

      <LoginTextInput
        description="Username"
        value={usernameInputValue}
        onChange={e => setUsernameInputValue(e.nativeEvent.text)}
        editable={canSubmit}
        textContentType="username"
        autoComplete="username"
        clearButtonMode="always"
        importantForAutofill="yes"
      />

      <LoginTextInput
        description="Password"
        value={passwordInputValue}
        onChange={e => setPasswordInputValue(e.nativeEvent.text)}
        editable={canSubmit}
        textContentType="password"
        autoComplete="password"
        clearButtonMode="always"
        importantForAutofill="yes"
        secureTextEntry={true}
      />

      <Box mt="2">
        <LoginSwitchInput
          description="Remember Me"
          value={rememberLoginDetails}
          onValueChange={rememberMeCallback}
          disabled={!canSubmit}
        />
      </Box>

      {!props.isLoading && (
        <Center mt="2">
          <InputFormButton disabled={!canSubmit} onPress={loginCallback} />
        </Center>
      )}
    </VStack>
  );
}
export namespace LoginForm {
  export type RequestResponse =
    | { _type: 'validationError'; message: string }
    | { _type: 'validated'; details: RequestDetails };

  export type RequestDetails = {
    readonly serverId: string;
    readonly username: string;
    readonly password: string;
    readonly rememberMe: boolean;
  };

  export function validateRequest(details: RequestDetails) {
    // return true || details;
    return (
      details.serverId.length > 0 &&
      details.username.length > 0 &&
      details.password.length > 0
    );
  }
}
