import React, { PropsWithChildren } from 'react';
import { RefreshControlProps } from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control'
import { ScrollView } from 'native-base';
import { ColorType } from 'native-base/lib/typescript/components/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
  background?: ColorType;
  insetTop?: number;
  insetBottom?: number;
} & Pick<RefreshControlProps, 'onRefresh' | 'refreshing'>;

const ScrollViewWithRefresh = (props: PropsWithChildren<Props>) => {
  const screenInsets = useSafeAreaInsets();

  const scrollViewInset = props.insetTop ?? 0;
  const scrollViewInsetBottom = screenInsets.bottom + (props.insetBottom ?? 0);

  return (
    <ScrollView
      background={props.background}

      contentContainerStyle={{
        paddingTop: scrollViewInset,
        paddingBottom: scrollViewInsetBottom,
        paddingLeft: screenInsets.left,
        paddingRight: screenInsets.right,
      }}
      refreshControl={
        <RefreshControl
          progressViewOffset={scrollViewInset}
          refreshing={props.refreshing}
          onRefresh={props.onRefresh}
        />
      }>
      {props.children}
     </ScrollView>
  );
};

export default ScrollViewWithRefresh;
