import React from 'react';
import { Divider, SectionList } from 'native-base';
import { groupBy } from 'lodash';
import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';
import { DateRange } from '@marango/api/src/time/dateRange';
import dayRange from '@marango/api/src/time/dayRange';
import { ITimestamped } from '@marango/api/src/time/ITimestamped';

import ScheduleTableErrorCell from '../molecules/ScheduleTable/ScheduleTableErrorCell';
import ScheduleTableLoading from '../molecules/ScheduleTable/ScheduleTableLoading';
import ScheduleTableNoDataCell from '../molecules/ScheduleTable/ScheduleTableNoDataCell';
import ScheduleTableDateHeader from '../molecules/ScheduleTable/ScheduleTableDateHeader';
import { SectionListData } from 'react-native';
import { DateTime } from 'luxon';
import { dateStringFromLuxon } from '@marango/api/src/models/DateString';

type ScheduleTablePropsBase<D extends ITimestamped> = {
  range: DateRange;
  hideEmptyDays?: boolean;
  hideHeaders?: boolean;
  hideItemSeperator?: boolean;
  onEndReached?: () => void
  renderDetail: (detail: D, key: number, isFirst: boolean, isLast: boolean) => React.ReactElement;
};

type ScheduleTablePropsLoadable<D extends ITimestamped> =
  ScheduleTablePropsBase<D> & {
    loadable: AsyncLoadable<ReadonlyArray<D>>;
  };

type ScheduleTablePropsLoaded<D extends ITimestamped> =
  ScheduleTablePropsBase<D> & {
    data: ReadonlyArray<D>;
  };

function ScheduleTable<D extends ITimestamped>(
  props: ScheduleTablePropsLoadable<D>,
): JSX.Element {
  if (props.loadable._type === 'error') {
    return <ScheduleTableErrorCell message={props.loadable.error} />;
  } else {
    if (props.loadable.data === undefined || props.loadable._type === 'loading') {
      return <ScheduleTableLoading />;
    } else {
      if (props.loadable.data?.length === 0) {
        return <ScheduleTableNoDataCell />;
      }
      return (
        <ScheduleTableLoaded
          data={props.loadable.data}
          {...props}
        />
      );
    }
  }
}

function ScheduleTableLoaded<D extends ITimestamped>(
  props: ScheduleTablePropsLoaded<D>,
) {
  const dates = dayRange(props.range);
  const groupedData: Record<string, D[] | undefined> = groupBy(props.data, d =>
    d.timestamp.toISODate(),
  );

  const sectionData = dates.map<
    SectionListData<D, { dateTime: DateTime }>
  >(dateTime => {
    return {
      dateTime,
      key: dateStringFromLuxon(dateTime),
      data: groupedData[dateTime.toISODate()] ?? [],
    };
  }).reverse();

  const filteredData = props.hideEmptyDays
    ? sectionData.filter(({ dateTime }) => (groupedData[dateTime.toISODate()] ?? []).length > 0)
    : sectionData;
console.log(filteredData)
  let renderSectionHeader: ((arg: { section: SectionListData<D, { dateTime: DateTime; }> }) => React.ReactElement) | undefined = ({ section }) => (
    <ScheduleTableDateHeader
      date={section.dateTime}
      key={`header-${section.key}`} />
  );

  if (props.hideHeaders === true) {
    renderSectionHeader = undefined
  }

  const totalRows = filteredData.flatMap(d => d.data).length

  return (
    <SectionList
      pb="2"
      sections={filteredData}
      keyExtractor={(_, i) => `${i}`}
      onEndReached={props.onEndReached}
      renderItem={item => {
        const isFirst = item.index === 0
        const isLast = item.index === totalRows - 1

        return <>
          {props.renderDetail(item.item, item.index, isFirst, isLast)}
        </>
      }}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={({ section }) =>
        section.data.length === 0
          ? <ScheduleTableNoDataCell key={`header-${section.key}`} />
          : null
      }
    />
  );
}

export default ScheduleTable;
