import React from 'react';
import { NativeSyntheticEvent, NativeTouchEvent } from 'react-native';
import { Button } from 'native-base';

type Props = {
  onPress: (ev: NativeSyntheticEvent<NativeTouchEvent>) => void;
  disabled?: boolean;
  children?: string;
};

const InputFormButton = (props: Props) => {
  const buttonText = props.children ?? "Login"

  return <Button
    width="100%"
    onPress={props.onPress}
    isDisabled={props.disabled}
    borderRadius={20}
    colorScheme="green"
    _text={{ color: 'white' }}
    size="lg">
    {buttonText}
  </Button>
};

export default InputFormButton;
