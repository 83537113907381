import { makeAutoObservable } from 'mobx';
import { makeLoggable } from 'mobx-log';
import { NavigationStore } from './NavigationStore';
import { FreightJob } from '../models/FreightJob/FreightJob';
import { EntityStore } from './EntityStore';
import { UserStore } from './UserStore';
import { DateRangeEntityStore } from './DateRangeEntityStore';
import { FreightJobTypes } from '../models/FreightJob/Types';
import { ContractTypes, RCTITypes } from '../models/Grower/Types';
import { Contract } from '../models/Grower/Contract';
import { EntityListStore } from './EntityListStore';
import { RCTI } from '../models/Grower/RCTI';
import { IAuthTokenStorageService } from '../Services/AuthTokenStorageService';
import { Warehouse } from '../models/Grower/Warehouse';
import { Ticket } from '../models/Grower/Ticket';


export class RootStore {
  readonly userStore: UserStore

  // API Entities
  // - Freight
  readonly freightJobStore: EntityStore<FreightJobTypes.Id, FreightJob>
  readonly freightJobRangeStore: DateRangeEntityStore<FreightJob>

  // - Grower
  readonly contractStore: EntityStore<ContractTypes.Id, Contract>
  // readonly contractListStore: EntityListStore<Contract>
  //readonly rctiListStore: EntityListStore<RCTI>
  readonly warehouseListStore: EntityListStore<Warehouse>

  constructor(authStorageService: IAuthTokenStorageService) {
    this.userStore = new UserStore(authStorageService);

    const getApi = () => this.userStore.getMarangoApiOrError()

    this.freightJobStore = new EntityStore(
      id => getApi().getJobForId(id),
      e => e.jobId
    )

    this.freightJobRangeStore = new DateRangeEntityStore(
      dateRange => getApi().getJobsForRange(dateRange),
      e => e.jobId
    )

    this.contractStore = new EntityStore(
      id => getApi().getContractForId(id),
      e => e.contractId
    )

    // this.contractListStore = new EntityListStore(
    //   () => getApi().getContracts(),
    //   e => e.contractId
    // )

    //this.rctiListStore = new EntityListStore(
    //  () => getApi().getRCTIs()
    // )

    this.warehouseListStore = new EntityListStore(
      () => getApi().getWarehouseLevels()
    )
  }
}