import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import ChangePasswordPage from '../../Components/pages/Grower/ChangePasswordPage';

import { useRootStore } from '../../Stores/useRootStore';
import { useNavigation } from '@react-navigation/core';
import { GrowerPortalNavigationProp } from '../GrowerPortal/GrowerRootStackNavigator';
import { ChangePasswordForm } from '../../Components/organism/ChangePasswordForm';
import { ChangePasswordRequest } from '@marango/api/src/models/user/ChangePasswordRequest';
import { MarangoApiError } from '@marango/api/src/Services/MarangoApi';

const ChangePasswordPageContainer = observer(() => {
  const { userStore } = useRootStore();
  const navigation = useNavigation<GrowerPortalNavigationProp>();

  const [serverError, setServerError] = useState<string | undefined>();
  const [currentPasswordError, setCurrentPasswordError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const onChangePassword = useCallback(async (r: ChangePasswordForm.RequestDetails) => {
    try {
      setLoading(true);
      setServerError(undefined)
      setCurrentPasswordError(undefined)
      setShowSuccessAlert(false)

      await userStore.getMarangoApiOrError().changePassword({
        currentPassword: r.currentPassword as ChangePasswordRequest.CurrentPassword,
        newPassword: r.newPassword as ChangePasswordRequest.NewPassword,
      })

      setShowSuccessAlert(true)
     } catch (e: unknown) {
      if (e instanceof MarangoApiError) {
        if (e.message.includes("current_password")) {
          // Cleanup the error message
          setCurrentPasswordError("Current password does not match.")
        } else {
          setServerError(e.message)
        }
      } else {
        console.error(e)
        setServerError("An unknown error occurred, please try again later.")
      }
    } finally {
      setLoading(false);
    }
  }, [navigation, userStore, setLoading, setServerError, setCurrentPasswordError])

  const onSuccessAlertConfirm = useCallback(() => {
    setShowSuccessAlert(false)
    navigation.goBack()
  }, [])

  return (
    <ChangePasswordPage
      onSubmit={onChangePassword}
      serverError={serverError}
      currentPasswordError={currentPasswordError}
      isLoading={loading}
      showSuccessAlert={showSuccessAlert}
      onSuccessAlertConfirm={onSuccessAlertConfirm}
    />
  );
});

export default ChangePasswordPageContainer;
