import { makeAutoObservable } from 'mobx';
import { makeLoggable } from 'mobx-log';

import { UserLoginDetails } from '../models/user/UserLoginDetails';
import { MarangoApiError, MarangoApiService } from '../Services/MarangoApi';
import { IAuthTokenStorageService } from '../Services/AuthTokenStorageService';

type UserStateInitialising = {
  readonly _type: 'UserState.Initialising';
};


type UserStateUnauthenticated = {
  readonly _type: 'UserState.Unauthenticated';
  readonly error?: string;
};

type UserStateAuthenticating = {
  readonly _type: 'UserState.Authenticating';
};

type UserStateAuthenticated = {
  readonly _type: 'UserState.Authenticated';
  readonly marangoApi: MarangoApiService;
};

type UserState =
  | UserStateInitialising
  | UserStateUnauthenticated
  | UserStateAuthenticating
  | UserStateAuthenticated;

export class UserNotAuthenticatedError extends Error {
  constructor() {
    super();
    this.name = 'UserNotAuthenticatedError';
  }
}

export class UserStore {
  private _state: UserState = { _type: 'UserState.Initialising' };

  constructor(
    readonly storageProvider: IAuthTokenStorageService
  ) {
    this.init()
    makeAutoObservable(this, { storageProvider: false });
    // makeLoggable(this);
  }

  get state() {
    return this._state;
  }

  get error(): string | undefined {
    return this._state._type === 'UserState.Unauthenticated'
      ? this._state.error
      : undefined;
  }

  get isAuthentecating() {
    return this._state._type === 'UserState.Authenticated';
  }

  get isLoggedIn() {
    return this._state._type === 'UserState.Authenticated';
  }

  setAuthenticating() {
    if (this._state._type !== 'UserState.Unauthenticated') {
      return;
    }

    this._state = { _type: 'UserState.Authenticating' };
  }

  setAuthenticated(marangoApi: MarangoApiService) {
    if (this._state._type !== 'UserState.Authenticating' && this._state._type !== 'UserState.Initialising') {
      return;
    }

    this._state = {
      _type: 'UserState.Authenticated',
      marangoApi,
    };
  }

  setUnauthenticated(error?: string) {
    this._state = {
      _type: 'UserState.Unauthenticated',
      error
    };
  }

  async logout(error?: string) {
    await this.storageProvider.forgetAuthToken()
    this.setUnauthenticated(error)
  }

  async init(): Promise<void> {
    if (this._state._type !== 'UserState.Initialising') {
      return
    }

    try {
      const authToken = await this.storageProvider.retrieveAuthToken()

      if (authToken) {
        const apiService = new MarangoApiService(authToken);

        // A kinda hacky check to ensure the token is still valid
        try {
          await apiService.version()
        } catch (e) {
          if (e instanceof MarangoApiError) {
            // We're only explicitly looking for 401s here cause i don't want random server
            // downtime needlessly invalidating valid tokens
            if (e.statusCode === 401) {
              throw e
            }
          }
        }
        this.setAuthenticated(apiService);
      } else {
        this.setUnauthenticated()
      }
    } catch (e: any) {
      this.setUnauthenticated()
    }
  }

  async login(
    userDetails: UserLoginDetails
  ) {
    try {
      this.setAuthenticating();
      const apiService = await MarangoApiService.login(userDetails);
      this.setAuthenticated(apiService);
      if (userDetails.rememberMe === true) {
        await this.storageProvider.saveAuthToken(apiService.authToken)
      }
    } catch (e: unknown) {
      if (e instanceof MarangoApiError) {
        await this.logout(e.message);
      }

      await this.logout();
    }
  }

  getMarangoApiOrError(): MarangoApiService {
    if (this._state._type === 'UserState.Authenticated') {
      return this._state.marangoApi;
    } else {
      throw new UserNotAuthenticatedError();
    }
  }
}
