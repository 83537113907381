import { DateTime } from 'luxon';

/** A Date String in the format `YYYY-MM-DD` or `1995-01-15` */
export type DateString = NominalPrimative<string, 'DateString'>;

export function dateStringToLuxon(dateString: DateString): DateTime {
  return DateTime.fromFormat(dateString, 'y-MM-dd');
}

export function dateStringFromLuxon(luxon: DateTime): DateString {
  return luxon.toFormat('y-MM-dd') as DateString;
}
