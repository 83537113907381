import { CommodityTypes, WarehouseTypes } from "./Types";

export class Warehouse {
  public constructor(
    readonly siteName: WarehouseTypes.Name,
    readonly levels: ReadonlyArray<WarehouseLevel>
  ) { }
}

export class WarehouseLevel {
  public constructor(
    readonly commoditySeason: CommodityTypes.Season,
    readonly commodityName: CommodityTypes.Name,
    readonly commodityGrade: CommodityTypes.Grade | undefined,
    readonly unitName: string,
    readonly amount: WarehouseTypes.Level.Amount
  ) { }

  get commodityNameAndGrade(): string {
    if (this.commodityGrade) {
      return `${this.commodityName} - ${this.commodityGrade}`
    } else {
      return `${this.commodityName}`
    }
  }

  get commodityKey(): string {
    return `${this.commodityNameAndGrade} - ${this.commoditySeason}`
  }
}