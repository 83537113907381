import React, { useEffect } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Box, VStack } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { AsyncLoadable } from '@marango/api/src/AsyncLoadable';

import ScrollViewWithRefresh from '../../molecules/Layout/ScrollViewWithRefresh';
import Bubble from '../../atoms/Bubble';
import { TableView } from '../../molecules/TableView';
import { Colors } from '../../../Colors';
import { GrowerPortalNavigationProp } from '../../../Containers/GrowerPortal/GrowerRootStackNavigator';
import { Contract } from '@marango/api/src/models/Grower/Contract';
import DateFormatter from '@marango/api/src/formatters/dateFormatter';
import { observer } from 'mobx-react-lite';
import DialogBox from '../../atoms/DialogBox';

type Props = {
  contract: AsyncLoadable<Contract>;
  onRefresh: () => void;
};

const ContractDetailPage = observer<Props>(props => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<GrowerPortalNavigationProp>();

  useEffect(() => {
    if (props.contract._type === 'loaded') {
      navigation.setOptions?.({
        headerTitle: `Contract Details - ${props.contract.data.contractNo}`
      })
    } else {
      navigation.setOptions?.({
        headerTitle: `Contract Details - ...`
      })
    }
  }, [navigation, props.contract._type])

  const isRefreshing = props.contract._type === 'loading';
  const errorMessage = props.contract._type === "error" ? props.contract.error : undefined

  let loadedContract: Contract | undefined;
  if (props.contract._type !== 'error') {
    loadedContract = props.contract.data;
  }


  return (
    <Box
      flex={1}
      bg={Colors.rgba('BACKGROUND_BLUE')}
      height="100%"
      width="100%">
      <ScrollViewWithRefresh
        refreshing={isRefreshing}
        onRefresh={props.onRefresh}
        insetBottom={insets.bottom}>
        {errorMessage && (
          <Box mt="5">
            <DialogBox
              title="Error Loading Contract"
              description={errorMessage}
              severity="error"
            />
          </Box>
        )}
        {loadedContract && <ContractDetailPageBody contract={loadedContract} />}
      </ScrollViewWithRefresh>
    </Box>
  );
});

const ContractDetailPageBody = (
  props: { contract: Contract }
) => {
  return (
    <Box mx="3">
      <VStack divider={<Box marginTop="2" />} marginY="2">
        <Bubble>
          <TableView.Container>
            <TableView.Cell name={'Date'} value={DateFormatter(props.contract.timestamp)} />
            <TableView.Cell name={'Grade'} value={props.contract.commodityKey} />
            <TableView.Cell name={'Season'} value={props.contract.commoditySeason} />
            <TableView.Cell name={'Price'} value={`$${props.contract.basePricePerUnit} / ${props.contract.unitString}`} />
            <TableView.Cell name={'Delivery'} value={`${props.contract.deliveryFrom ?? ''} - ${props.contract.deliveryTo ?? ''}`} />
            <TableView.Cell name={'Location'} value={props.contract.siteName} seperateRows />
            <TableView.Cell name={'Comments'} value={props.contract.comments ?? ""} seperateRows />
          </TableView.Container>
        </Bubble>
      </VStack>
    </Box>
  );
};

export default ContractDetailPage;
