import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import SettingsListPage from '../../Components/pages/Grower/SettingsListPage';

import { useRootStore } from '../../Stores/useRootStore';
import { useNavigation } from '@react-navigation/core';
import { GrowerPortalNavigationProp } from '../GrowerPortal/GrowerRootStackNavigator';

const SettingsListPageContainer = observer(() => {
  const { userStore } = useRootStore();
  const navigation = useNavigation<GrowerPortalNavigationProp>();

  const onLogout = useCallback(() => {
    userStore.logout()
  }, [userStore])

  const onChangePassword = useCallback(() => {
    navigation.navigate('settings', { screen: 'change-password' })
  }, [navigation])

  return (
    <SettingsListPage
      onLogout={onLogout}
      onChangePassword={onChangePassword}
    />
  );
});

export default SettingsListPageContainer;
