import React, { useCallback, useEffect } from 'react';
import { Box, HStack } from 'native-base';
import { useNavigation } from '@react-navigation/native';

import DateFormatter from '@marango/api/src/formatters/dateFormatter';
import { DateRange } from '@marango/api/src/time/dateRange';

import IconButton from '../atoms/IconButton';

type BaseProps = {
  onNavigation: (newRange: DateRange) => void;
  onRefresh: () => void;
};

type Props =
  | BaseProps & { range: DateRange; style: 'month'; }
  | BaseProps & { range: DateRange; style: 'week'; }
  | BaseProps & { title: string; style: 'fixed'; }

const DateNavigationHeader = (props: Props) => {
  const navigation = useNavigation();

  const onPrev = useCallback(() => {
    if (props.style === 'fixed') { return }
    const newStart = props.range.start.minus({ weeks: 1 });
    const newEnd = props.range.end.minus({ weeks: 1 });
    const newRange: DateRange = {
      start: newStart,
      end: newEnd,
    };
    props.onNavigation(newRange);
  }, [props]);

  const onNext = useCallback(() => {
    if (props.style === 'fixed') { return }
    const newStart = props.range.start.plus({ weeks: 1 });
    const newEnd = props.range.end.plus({ weeks: 1 });
    const newRange: DateRange = {
      start: newStart,
      end: newEnd,
    };
    props.onNavigation(newRange);
  }, [props]);

  const onRefresh = useCallback(() => {
    props.onRefresh();
  }, [props]);

  let header: string

  switch (props.style) {
    case 'week': {
      const startDateString = DateFormatter(props.range.start, 'dayMonth');
      const endDateString = DateFormatter(props.range.end, 'dayMonth');
      header = `Week: ${startDateString} - ${endDateString}`;
      break;
    }
    case 'month': {
      header = DateFormatter(props.range.start, 'monthYear');
      break;
    }
    case 'fixed': {
      header = props.title
      break;
    }
  }

  useEffect(() => {
    const headerLeft = () => (
      <Box marginX="3">
        <IconButton icon="refresh" onPress={onRefresh} />
      </Box>
    );

    const headerRight = () => (
      <HStack space={1} marginX="3">
        <IconButton icon="chevronLeft" onPress={onPrev} />
        <IconButton icon="chevronRight" onPress={onNext} />
      </HStack>
    );

    if (props.style === 'fixed') {
      navigation.setOptions?.({
        headerTitle: header,
        headerLeft,
      });
    } else {
      navigation.setOptions?.({
        headerTitle: header,
        headerLeft,
        headerRight,
      });
    }
  }, [header, navigation, onRefresh, onNext, onPrev]);

  return null;
};

export default DateNavigationHeader;
