import React, { PropsWithChildren } from 'react';
import { ViewStyle } from 'react-native';
import { Text, View } from 'native-base';
import { TextAlignProps } from 'styled-system';

import Color from 'color';

import { IFontSize } from 'native-base/lib/typescript/theme/base/typography';
import { ColorType } from 'native-base/lib/typescript/components/types';
import assertNever from '@marango/api/src/utils/assertNever';

type LabelVariant =
  | 'small'
  | 'body'
  | 'large'
  | 'header'
  | 'jumbo'
  | 'jumboHeader'
  | 'inputLabel'
  | 'minor';

interface Props {
  variant?: LabelVariant;
  color?: ColorType;
  wrap?: boolean;
  bold?: boolean;
  italic?: boolean;
  underlined?: boolean;
  textAlign?: TextAlignProps['textAlign'];
}

const Label = (props: PropsWithChildren<Props>) => {
  let fontSize: IFontSize;
  let isBold: boolean = props.bold === true;
  let isItalic: boolean = props.italic === true;
  let isUnderlined: boolean = props.underlined === true;

  const variant = props.variant ?? 'body';
  switch (variant) {
    case 'small': {
      fontSize = 'xs';
      break;
    }
    case 'body': {
      fontSize = 'sm';
      break;
    }
    case 'inputLabel': {
      fontSize = 'sm';
      isBold = true;
      break;
    }
    case 'header': {
      fontSize = 'xl';
      break;
    }
    case 'large': {
      fontSize = 'lg';
      break;
    }
    case 'jumbo': {
      fontSize = '2xl';
      break;
    }
    case 'jumboHeader': {
      fontSize = '4xl'
      break
    }
    case 'minor': {
      fontSize = 'sm';
      isItalic = true;
      break;
    }
    default:
      assertNever(variant);
  }

  let containerStyle: ViewStyle = {};
  if (props.wrap === true) {
    containerStyle.flexShrink = 1;
  }

  const numOfLines = props.wrap === true ? 0 : 1;

  return (
    <View style={containerStyle}>
      <Text
        fontSize={fontSize}
        bold={isBold}
        italic={isItalic}
        underline={isUnderlined}
        color={props.color ?? Color('#000').rgb().string()}
        ellipsizeMode="tail"
        numberOfLines={numOfLines}
        textAlign={props.textAlign}>
        {props.children}
      </Text>
    </View>
  );
};

export default Label;
